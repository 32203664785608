@font-face {
  font-family: 'playericonfont';
  src: url('../../../../v-01001251236/v3/fonts/skins/player/playericonfont//playericonfont.woff2') format('woff2'), url('../../../../v-01001251236/v3/fonts/skins/player/playericonfont//playericonfont.ttf') format('truetype'), url('../../../../v-01001251236/v3/fonts/skins/player/playericonfont//playericonfont.woff') format('woff'), url('../../../../v-01001251236/v3/fonts/skins/player/playericonfont//playericonfont.svg#playericonfont') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.player-icon-f {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'playericonfont' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* === ICONS START === */
.pif-angle-double-left:before {
  content: "\e917";
}
.pif-angle-double-right:before {
  content: "\e918";
}
.pif-angle-left:before {
  content: "\e900";
}
.pif-angle-right:before {
  content: "\e901";
}
.pif-chromecast:before {
  content: "\e902";
}
.pif-close:before {
  content: "\e911";
}
.pif-download:before {
  content: "\e904";
}
.pif-external:before {
  content: "\e91b";
}
.pif-feed:before {
  content: "\e905";
}
.pif-full-screen:before {
  content: "\e906";
}
.pif-full-width:before {
  content: "\e907";
}
.pif-hd:before {
  content: "\e908";
}
.pif-info-circle:before {
  content: "\e91e";
}
.pif-internal:before {
  content: "\e91c";
}
.pif-link:before {
  content: "\e909";
}
.pif-loop:before {
  content: "\e903";
}
.pif-pause:before {
  content: "\e910";
}
.pif-play:before {
  content: "\e90a";
}
.pif-quality:before {
  content: "\e90b";
}
.pif-repeat:before {
  content: "\e912";
}
.pif-save:before {
  content: "\e91d";
}
.pif-settings:before {
  content: "\e919";
}
.pif-settings-advanced:before {
  content: "\e91a";
}
.pif-sound-0:before {
  content: "\e90c";
}
.pif-sound-1:before {
  content: "\e90d";
}
.pif-sound-2:before {
  content: "\e90e";
}
.pif-sound-mute:before {
  content: "\e913";
}
.pif-sound-mute-x:before {
  content: "\e914";
}
.pif-speed:before {
  content: "\e90f";
}
.pif-spinner:before {
  content: "\e93a";
}
.pif-step-backward:before {
  content: "\e915";
}
.pif-step-forward:before {
  content: "\e916";
}
/* === ICONS END === */
.pif-sound-colored,
.pif-sound-mute-colored {
  box-sizing: content-box;
  display: inline-block;
  height: 1em;
  position: relative;
  width: 1em;
}
.pif-sound-colored:after,
.pif-sound-mute-colored:after,
.pif-sound-colored:before,
.pif-sound-mute-colored:before {
  position: absolute;
  display: inline-block;
  top: 0;
  left: 0;
  height: 1em;
  width: 1em;
}
.pif-sound-mute-colored:before {
  content: "\e914";
  color: #de2600;
}
.pif-sound-mute-colored:after {
  content: "\e90c";
}
.pif-white-fill {
  position: relative;
}
.pif-white-fill:before {
  position: relative;
  z-index: 2;
}
.pif-white-fill:after {
  background: white;
  bottom: 12%;
  content: " ";
  left: 12%;
  position: absolute;
  right: 12%;
  top: 12%;
  z-index: 1;
}
.pif-white-fill.pif-hd:after {
  bottom: 27%;
  left: 14%;
  right: 14%;
  top: 24%;
}
.pif-full-screen-animated {
  display: inline-block;
  height: 1em;
  width: 1em;
  position: relative;
}
.pif-full-screen-animated span {
  height: 0.5em;
  overflow: hidden;
  position: absolute;
  transition: transform 0.1s ease-in-out;
  width: 0.5em;
}
.pif-full-screen-animated span:before {
  content: "\e906";
  display: inline-block;
  height: 1em;
  position: absolute;
  width: 1em;
}
.pif-full-screen-animated span:nth-child(1),
.pif-full-screen-animated span:nth-child(1):before {
  left: 0;
  top: 0;
}
.pif-full-screen-animated span:nth-child(2),
.pif-full-screen-animated span:nth-child(2):before {
  right: 0;
  top: 0;
}
.pif-full-screen-animated span:nth-child(3),
.pif-full-screen-animated span:nth-child(3):before {
  bottom: 0;
  left: 0;
}
.pif-full-screen-animated span:nth-child(4),
.pif-full-screen-animated span:nth-child(4):before {
  bottom: 0;
  right: 0;
}
.notouch .pif-full-screen-animated:hover span:nth-child(1) {
  transform: translate(-0.05em, -0.05em);
}
.notouch .pif-full-screen-animated:hover span:nth-child(2) {
  transform: translate(0.05em, -0.05em);
}
.notouch .pif-full-screen-animated:hover span:nth-child(3) {
  transform: translate(-0.05em, 0.05em);
}
.notouch .pif-full-screen-animated:hover span:nth-child(4) {
  transform: translate(0.05em, 0.05em);
}
.notouch:fullscreen .pif-full-screen-animated:hover span:nth-child(1),
.notouch *:fullscreen .pif-full-screen-animated:hover span:nth-child(1) {
  transform: translate(0.05em, 0.05em);
}
.notouch:fullscreen .pif-full-screen-animated:hover span:nth-child(2),
.notouch *:fullscreen .pif-full-screen-animated:hover span:nth-child(2) {
  transform: translate(-0.05em, 0.05em);
}
.notouch:fullscreen .pif-full-screen-animated:hover span:nth-child(3),
.notouch *:fullscreen .pif-full-screen-animated:hover span:nth-child(3) {
  transform: translate(0.05em, -0.05em);
}
.notouch:fullscreen .pif-full-screen-animated:hover span:nth-child(4),
.notouch *:fullscreen .pif-full-screen-animated:hover span:nth-child(4) {
  transform: translate(-0.05em, -0.05em);
}
body {
  --plyr-autoplay-act-bg: rgba(255, 255, 255, 0.9);
  --plyr-autoplay-act-color: rgba(22, 22, 22, 0.9);
  --plyr-checkbox-bg: #DE2600;
  --plyr-volume-active: #DE2600;
}
#html5video {
  position: relative;
}
#html5video,
#html5video * {
  box-sizing: content-box;
}
#html5video.fakefullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 400;
}
#html5video .noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
									  not supported by any browser */
}
#html5video .sdabox {
  position: absolute;
  height: 235px;
  width: 338px;
  background: #fff;
  border: 4px solid #000;
  padding: 4px;
  top: 5%;
  left: 10%;
}
#html5video .sdabox .sdacontainer {
  position: absolute;
  left: 4px;
  top: 4px;
  right: 4px;
  bottom: 4px;
}
#html5video .sdabox .sdacontainer img {
  width: 100%;
  height: 100%;
}
#html5video .sdabox .dlg-close-btn {
  position: absolute;
  top: -4px;
  right: -40px;
  background: rgba(0, 0, 0, 0.5);
}
#html5video .sdabox .sdaclick {
  position: absolute;
}
#html5video .sdabox .sdaclick.sda10pc {
  left: 10%;
  top: 10%;
  right: 10%;
  bottom: 10%;
}
#html5video .sdabox .sdaclick.sda15pc {
  left: 15%;
  top: 15%;
  right: 15%;
  bottom: 15%;
}
#html5video .sdabox .sdadblclick {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
  width: 100%;
  height: 100%;
}
#html5video #hlsplayer {
  overflow: hidden;
}
#html5video #hlsplayer > * {
  z-index: 1;
}
#html5video #hlsplayer.is-slow-seeking * {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
									  not supported by any browser */
}
#html5video #hlsplayer > #centered-full-video-link {
  z-index: 2;
}
#html5video #hlsplayer > .related-vids {
  z-index: 3;
}
#html5video #hlsplayer > .buttons-bar {
  z-index: 4;
}
#html5video #hlsplayer > .top-left {
  z-index: 4;
}
#html5video #hlsplayer > .videoad-title-invideo {
  z-index: 5;
}
#html5video #hlsplayer > .videoad-base {
  z-index: 6;
}
#html5video #hlsplayer .video-bg-pic {
  width: 100%;
  height: 100%;
  background-color: #000;
  position: relative;
}
#html5video #hlsplayer .video-bg-pic .video-pic,
#html5video #hlsplayer .video-bg-pic .video-click-handler {
  position: absolute;
  width: 2000px;
  height: 100%;
  top: 0;
  left: 50%;
  margin: 0 -1000px;
}
#html5video #hlsplayer .video-bg-pic .video-pic {
  background-color: #000;
  text-align: center;
}
#html5video #hlsplayer .video-bg-pic .video-pic img {
  height: 100%;
  margin: 0 auto;
}
#html5video #hlsplayer .video-bg-pic .video-pic.picture-related img {
  display: none;
}
#html5video #hlsplayer video {
  width: 100%;
  height: 100%;
  background-color: #000;
}
#html5video #hlsplayer video .ios_player::-webkit-media-controls-play-button,
#html5video #hlsplayer video .ios_player::-webkit-media-controls-start-playback-button {
  opacity: 0;
  pointer-events: none;
  width: 5px;
  display: none !important;
  -webkit-appearance: none;
}
#html5video #hlsplayer .player-icon-f {
  color: rgba(255, 255, 255, 0.9);
}
@keyframes fast_left_to_right {
  0% {
    left: -30px;
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    left: calc(100% - 30px);
    opacity: 0;
  }
}
@keyframes fast_right_to_left {
  0% {
    right: -30px;
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    right: calc(100% - 30px);
    opacity: 0;
  }
}
#html5video #hlsplayer .fastforward-left,
#html5video #hlsplayer .fastforward-right {
  height: 50%;
  left: 5%;
  overflow: hidden;
  position: absolute;
  text-align: center;
  top: 25%;
  transition: background 0.5s ease;
  width: 25%;
}
#html5video #hlsplayer .fastforward-left .player-icon-f,
#html5video #hlsplayer .fastforward-right .player-icon-f,
#html5video #hlsplayer .fastforward-left .fastforward-txt,
#html5video #hlsplayer .fastforward-right .fastforward-txt {
  border-radius: 6px;
  color: #fff;
  line-height: 60px;
  margin-top: -30px;
  opacity: 0;
  position: absolute;
  top: 50%;
}
#html5video #hlsplayer .fastforward-left .player-icon-f,
#html5video #hlsplayer .fastforward-right .player-icon-f {
  font-size: 60px;
}
#html5video #hlsplayer .fastforward-left .fastforward-txt,
#html5video #hlsplayer .fastforward-right .fastforward-txt {
  background: rgba(0, 0, 0, 0.3);
  transition: all 0.5s ease;
  width: 100%;
  z-index: 2;
}
#html5video #hlsplayer .fastforward-left.fastforward-visible .fastforward-txt,
#html5video #hlsplayer .fastforward-right.fastforward-visible .fastforward-txt {
  opacity: 1;
}
#html5video #hlsplayer .fastforward-left.fastforward-visible .pif-angle-double-left {
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-name: fast_right_to_left;
}
#html5video #hlsplayer .fastforward-right {
  left: auto;
  right: 5%;
}
#html5video #hlsplayer .fastforward-right.fastforward-visible .pif-angle-double-right {
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-name: fast_left_to_right;
}
#html5video #hlsplayer .slowseek-info {
  background: rgba(0, 0, 0, 0.6);
  left: 0;
  min-height: 26px;
  position: absolute;
  text-align: center;
  top: 0;
  right: 0;
}
#html5video #hlsplayer .slowseek-info .label {
  color: #fff;
  font-size: 14px;
  font-weight: normal;
  line-height: 16px;
  display: inline-block;
  max-width: calc(100% - 55px);
  padding: 5px 10px 5px 45px;
  position: relative;
  vertical-align: top;
}
@keyframes left_right_oscill {
  0% {
    transform: translateX(-8px);
  }
  25% {
    transform: translateX(-8px);
  }
  50% {
    transform: translateX(8px);
  }
  75% {
    transform: translateX(8px);
  }
  100% {
    transform: translateX(-8px);
  }
}
#html5video #hlsplayer .slowseek-info .label::after {
  animation: left_right_oscill 3s ease;
  animation-iteration-count: infinite;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  content: ' ';
  display: block;
  height: 10px;
  left: 15px;
  position: absolute;
  top: 8px;
  width: 10px;
  z-index: 2;
}
#html5video #hlsplayer .slowseek-info .label::before {
  background: #aaa;
  border-radius: 2px;
  content: ' ';
  display: block;
  height: 4px;
  left: 5px;
  position: absolute;
  top: 11px;
  width: 30px;
  z-index: 1;
}
#html5video #hlsplayer .video-loader {
  border-radius: 3px;
  position: absolute;
  width: 100px;
  height: 100px;
  background: rgba(0, 0, 0, 0.3);
}
#html5video #hlsplayer .video-loader .buffer {
  border-radius: 3px;
  position: absolute;
  background: rgba(255, 255, 255, 0.3);
  height: 0;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
}
#html5video #hlsplayer .video-loader .label {
  line-height: 100%;
  color: #fff;
  font-weight: bold;
  text-align: center;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
									  not supported by any browser */
}
#html5video #hlsplayer .error-dialog {
  background: #000;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 300;
}
#html5video #hlsplayer .error-dialog .error-content {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}
#html5video #hlsplayer .error-dialog .error-content p {
  color: #fff;
  font-size: 24px;
  padding: 10px 10px 20px;
}
#html5video #hlsplayer .error-dialog .error-content button {
  color: #fff;
  padding: 0px 20px;
  background: #111;
  border: 2px solid #888;
  border-radius: 5px;
  font-size: 20px;
  line-height: 35px;
}
.notouch #html5video #hlsplayer .error-dialog .error-content button:hover {
  background: #333;
}
#html5video #hlsplayer .message-dialog {
  position: absolute;
  width: 90%;
  height: 90%;
  top: 5%;
  left: 5%;
}
#html5video #hlsplayer .message-dialog .message-content {
  overflow-x: hidden;
  overflow-y: auto;
}
#html5video #hlsplayer .message-dialog .dlg-close-btn {
  position: absolute;
  top: -20px;
  right: -20px;
  background: rgba(0, 0, 0, 0.5);
}
#html5video #hlsplayer .top-left {
  left: 1%;
  margin: 0;
  max-width: 98%;
  position: absolute;
  text-align: left;
  top: 1%;
  vertical-align: text-top;
  z-index: 4;
}
#html5video #hlsplayer .top-left .big-button {
  float: left;
}
#html5video #hlsplayer .top-left .full-video-link {
  background: #fff;
  border-radius: 3px;
  color: #000;
  display: inline-block;
  margin-right: 4px;
  padding: 0 6px;
  text-decoration: none;
}
#html5video #hlsplayer .video-title {
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  display: inline-block;
  margin: 0;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#html5video #hlsplayer .video-title .playlist {
  font-weight: normal;
}
#html5video #hlsplayer .video-title .playlist .label {
  font-style: italic;
}
#html5video #hlsplayer .video-title .prefix {
  margin-right: 5px;
}
#html5video #hlsplayer .video-title .full-video-link {
  background: #fff;
  border-radius: 3px;
  color: #000;
  display: inline-block;
  margin-right: 6px;
  padding: 0 6px;
  text-decoration: none;
  vertical-align: text-bottom;
}
#html5video #hlsplayer .commercial-com {
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 3px;
  padding: 4px;
  font-size: 55%;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
}
#html5video #hlsplayer .video-ended-desktop button {
  border: 0;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  line-height: 14px;
  padding: 6px 8px;
  margin: 0 4px 0 0;
  text-transform: capitalize;
  vertical-align: top;
}
#html5video #hlsplayer .video-ended-desktop button,
#html5video #hlsplayer .video-ended-desktop button.active,
#html5video #hlsplayer .video-ended-desktop button:active,
#html5video #hlsplayer .video-ended-desktop button:focus {
  color: #fff;
  background: #262626;
}
#html5video #hlsplayer .video-ended-desktop button * {
  display: inline-block;
  float: none;
  font-size: 14px;
  line-height: 14px;
  padding: 0;
  vertical-align: top;
}
#html5video #hlsplayer .video-ended-desktop button .player-icon-f {
  padding-right: 4px;
}
#html5video #hlsplayer .browser-controls-dl-btn {
  position: absolute;
  top: 1%;
  right: 1%;
}
#html5video #hlsplayer .xv-logo {
  position: absolute;
}
#html5video #hlsplayer .quality-btn {
  text-align: center;
  cursor: pointer;
  z-index: 200;
  position: absolute;
  bottom: 50%;
  right: 1%;
}
#html5video #hlsplayer .quality-default {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
}
#html5video #hlsplayer .quality-auto {
  color: #000;
  background-color: rgba(150, 150, 150, 0.7);
}
#html5video #hlsplayer .quality-forced {
  color: #000;
  background-color: rgba(255, 255, 255, 0.7);
  font-weight: bold;
}
#html5video #hlsplayer .big-buttons {
  position: absolute;
  white-space: nowrap;
}
#html5video #hlsplayer .big-buttons.big-buttons-center {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
#html5video #hlsplayer .big-button {
  cursor: pointer;
  display: inline-block;
  text-align: center;
  vertical-align: top;
}
#html5video #hlsplayer .big-button img {
  background: rgba(0, 0, 0, 0.5);
}
#html5video #hlsplayer .big-button.pause,
#html5video #hlsplayer .big-button.mute {
  opacity: 0.3;
}
#html5video #hlsplayer .big-button.pause img,
#html5video #hlsplayer .big-button.mute img {
  background: rgba(0, 0, 0, 0.8);
}
#html5video #hlsplayer .big-button.pl-btn img {
  background: rgba(0, 0, 0, 0.5);
}
#html5video #hlsplayer .big-button.replay img {
  background: rgba(0, 0, 0, 0.75);
}
#html5video #hlsplayer .big-button span:not(.player-icon-f) {
  color: #fff;
  font-weight: bolder;
  font-size: 14px;
  text-shadow: 1px 1px 2px black, 0 0 0.5em black, 0 0 0.1em black;
}
#html5video #hlsplayer .big-button span.player-icon-f {
  display: inline-block;
  text-shadow: 0.03em 0.03em 0.1em black, 0 0 0.5em rgba(0, 0, 0, 0.5), 0 0 1em rgba(0, 0, 0, 0.5);
}
#html5video #hlsplayer .big-button span.player-icon-f + span:not(.player-icon-f) {
  display: block;
}
.notouch #html5video #hlsplayer .big-button {
  transition: transform 0.1s;
}
.notouch #html5video #hlsplayer .big-button:hover {
  transform: scale(1.1);
}
.notouch #html5video #hlsplayer .big-button.has-clickable-effect {
  transition: transform 0.1s ease, filter 0.1s ease;
}
.notouch #html5video #hlsplayer .big-button.has-clickable-effect.player-icon-f {
  transition: text-shadow 0.1s ease;
}
.notouch #html5video #hlsplayer .big-button.has-clickable-effect:active {
  transform: translateY(0.1em);
  filter: brightness(0.95);
}
.notouch #html5video #hlsplayer .big-button.has-clickable-effect:active .player-icon-f {
  text-shadow: 0.01em 0.01em 0.08em black, 0 0 0.5em rgba(0, 0, 0, 0.4), 0 0 0.12em rgba(0, 0, 0, 0.3);
}
.notouch #html5video #hlsplayer .big-button.has-clickable-effect:hover:active {
  transform: scale(1.1) translateY(0.1em);
}
#html5video #hlsplayer.play-clicked .big-button.play,
#html5video #hlsplayer.play-clicked .big-button.pl-btn {
  opacity: 0.7;
}
#html5video #hlsplayer.paused .big-button.pl-btn {
  opacity: 0.3;
}
#html5video #hlsplayer.paused .big-button.pl-btn img {
  background: rgba(0, 0, 0, 0.8);
}
#html5video #hlsplayer .progress-bar-bg {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.15) 60%, rgba(0, 0, 0, 0) 100%);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
#html5video #hlsplayer .progress-bar-container {
  position: absolute;
}
#html5video #hlsplayer .progress-bar-container .progress-text-mobile {
  color: #FFF;
  cursor: default;
  position: absolute;
  white-space: nowrap;
}
#html5video #hlsplayer .progress-bar {
  position: absolute;
  background-color: rgba(254, 254, 254, 0.35);
  transition: height 0.2s;
  cursor: pointer;
  width: 100%;
}
#html5video #hlsplayer .progress-bar .bufferdiv {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
#html5video #hlsplayer .progress-bar .buffer-elt {
  position: absolute;
  top: 0;
  height: 100%;
}
#html5video #hlsplayer .progress-bar .cursor {
  position: absolute;
  height: 100%;
  width: 5px;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
}
#html5video #hlsplayer .progress-bar .roundcursor {
  background: #AAA;
  border-radius: 50%;
  border: 1px solid #DDD;
  position: absolute;
  top: 0;
  box-shadow: 2px 2px 12px #555;
}
#html5video #hlsplayer .progress-bar .progress-text {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #FFF;
  cursor: default;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
#html5video #hlsplayer .progress-bar .pgbar-cursor-detect {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -5px;
}
#html5video #hlsplayer .progress-bar .seek-cursor {
  z-index: 150;
  position: absolute;
  bottom: 100%;
}
#html5video #hlsplayer .progress-bar .seek-cursor .seek-thumb {
  position: relative;
  width: 180px;
  height: 135px;
  background: #000 0 0 no-repeat;
  border: 1px solid #fff;
}
#html5video #hlsplayer .progress-bar .seek-cursor .seek-text {
  position: absolute;
  left: 10px;
  bottom: 0;
  font-size: 20px;
  color: #fff;
  font-weight: bold;
  text-shadow: 2px 0 0 #000, -2px 0 0 #000, 0 2px 0 #000, 0 -2px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000;
  cursor: default;
}
#html5video #hlsplayer .progress-bar .seek-cursor .fake-cursor {
  height: 10px;
  width: 5px;
  margin: 1px auto;
  background: rgba(0, 0, 0, 0.7);
  border: 1px solid #fff;
}
#html5video #hlsplayer .progress-bar .seek-cursor .hide-cursor {
  height: 10px;
  width: 5px;
}
#html5video #hlsplayer .progress-bar .fake-cursor-desktop-container {
  height: 10px;
  width: 5px;
  z-index: 150;
  position: absolute;
}
#html5video #hlsplayer .progress-bar .fake-cursor-desktop-container .fake-cursor-desktop {
  height: 8px;
  width: 3px;
  margin: 0px auto;
  background: rgba(0, 0, 0, 0.7);
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
}
#html5video #hlsplayer .has-clickable-effect {
  transition: transform 0.1s ease, filter 0.1s ease;
}
#html5video #hlsplayer .has-clickable-effect:active {
  transform: scale(0.85);
  filter: brightness(0.8);
}
#html5video #hlsplayer .buttons-bar {
  bottom: 0;
  position: absolute;
  z-index: 4;
}
#html5video #hlsplayer .buttons-bar .player-icon-f {
  display: inline-block;
}
#html5video #hlsplayer .buttons-bar .player-icon-f:before {
  vertical-align: top;
}
#html5video #hlsplayer .buttons-bar.left {
  left: 0;
}
#html5video #hlsplayer .buttons-bar.right {
  right: 0;
}
#html5video #hlsplayer .buttons-bar.topleft {
  left: 0;
  top: 0;
  bottom: auto;
  z-index: 7;
}
#html5video #hlsplayer .buttons-bar > * {
  vertical-align: middle;
  cursor: pointer;
  display: inline-block;
}
#html5video #hlsplayer .buttons-bar .subscribe {
  display: inline-block;
}
#html5video #hlsplayer .buttons-bar .volume-bar {
  transition: width 0.2s, margin 0.2s;
}
#html5video #hlsplayer .buttons-bar .volume-bar,
#html5video #hlsplayer .buttons-bar .volume-bar .volume-bar-fill {
  display: inline-block;
  position: relative;
}
#html5video #hlsplayer .buttons-bar .volume-bar:before,
#html5video #hlsplayer .buttons-bar .volume-bar .volume-bar-fill:before {
  content: "";
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  z-index: 1;
}
#html5video #hlsplayer .buttons-bar .volume-bar:before {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 0.1em;
  height: 0.2em;
  width: 98%;
}
#html5video #hlsplayer .buttons-bar .volume-bar .volume-bar-fill {
  float: left;
  width: 100%;
  height: 100%;
}
#html5video #hlsplayer .buttons-bar .volume-bar .volume-bar-fill:before {
  background: #DE2600;
  background: var(--plyr-volume-active, #DE2600);
  border-radius: 0.125em;
  height: 0.25em;
  width: 100%;
}
#html5video #hlsplayer .buttons-bar .volume-bar .volume-bar-fill:after {
  background: #DE2600;
  background: var(--plyr-volume-active, #DE2600);
  box-shadow: 0 0 2px #000;
  border-radius: 50%;
  content: "";
  height: 0.5em;
  width: 0.5em;
  right: -0.25em;
  top: 50%;
  position: absolute;
  transition: font-size 0.15s;
  transform: translateY(-50%);
  z-index: 2;
}
#html5video #hlsplayer .buttons-bar .volume-bar.is-hidden-param {
  display: none;
}
#html5video #hlsplayer .buttons-bar .volume-bar:not(:hover) .volume-bar-fill:after {
  font-size: 0;
}
#html5video #hlsplayer .buttons-bar .volume-bar.is-muted {
  width: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
#html5video #hlsplayer .buttons-bar .volume-bar.is-muted .volume-bar-fill:after,
#html5video #hlsplayer .buttons-bar .volume-bar.is-muted:not(:hover) .volume-bar-fill:after {
  font-size: 0;
}
#html5video #hlsplayer .buttons-bar .volume-bar + .progress-text {
  margin-left: 1em;
}
#html5video #hlsplayer .buttons-bar .progress-text {
  display: inline-block;
  font-size: 12px;
  color: #fff;
  font-weight: normal;
}
#html5video #hlsplayer .buttons-bar .pl-tooltip {
  position: absolute;
  display: flex;
  flex-direction: column;
}
#html5video #hlsplayer .buttons-bar .pl-tooltip.pl-tooltip-bottom-left,
#html5video #hlsplayer .buttons-bar .pl-tooltip.pl-tooltip-bottom-right {
  flex-direction: column-reverse;
}
#html5video #hlsplayer .buttons-bar .pl-tooltip.pl-tooltip-fadeout {
  opacity: 0;
}
#html5video #hlsplayer .buttons-bar .pl-tooltip.pl-tooltip-top-left {
  top: 0;
  left: 0;
  transform: translateY(-100%);
}
#html5video #hlsplayer .buttons-bar .pl-tooltip.pl-tooltip-top-right {
  top: 0;
  right: 0;
  transform: translateY(-100%);
  align-items: flex-end;
}
#html5video #hlsplayer .buttons-bar .pl-tooltip.pl-tooltip-bottom-left {
  bottom: 0;
  left: 0;
  transform: translateY(100%);
}
#html5video #hlsplayer .buttons-bar .pl-tooltip.pl-tooltip-bottom-right {
  bottom: 0;
  right: 0;
  transform: translateY(100%);
  align-items: flex-end;
}
#html5video #hlsplayer .buttons-bar .pl-tooltip .pl-tooltip-text {
  display: flex;
  gap: 5px;
  align-items: flex-start;
  width: fit-content;
  background: #181818;
  color: #fff;
  font-size: 12px;
  padding: 4px 8px;
  text-align: left;
}
#html5video #hlsplayer .buttons-bar .pl-tooltip .pl-tooltip-text .pl-tooltip-link {
  color: #fff;
  text-decoration: none;
  text-align: center;
  overflow-wrap: normal;
  padding: 7px;
}
#html5video #hlsplayer .buttons-bar .pl-tooltip .pl-tooltip-text .pl-tooltip-link:hover {
  color: #ccc;
}
#html5video #hlsplayer .buttons-bar .pl-tooltip .pl-tooltip-text .pl-tooltip-link .player-icon-f {
  font-size: 1.4em;
  vertical-align: top;
  line-height: 0.714em;
  display: inline-block;
  margin: 0 0.2em;
}
#html5video #hlsplayer .buttons-bar .pl-tooltip .pl-tooltip-arrow {
  border-color: transparent;
  border-style: solid;
  width: 0;
  height: 0;
}
#html5video #hlsplayer .buttons-bar .pl-tooltip.pl-tooltip-top-left .pl-tooltip-arrow,
#html5video #hlsplayer .buttons-bar .pl-tooltip.pl-tooltip-top-right .pl-tooltip-arrow {
  border-width: 4px 4px 0;
  border-top-color: #181818;
}
#html5video #hlsplayer .buttons-bar .pl-tooltip.pl-tooltip-bottom-left .pl-tooltip-arrow,
#html5video #hlsplayer .buttons-bar .pl-tooltip.pl-tooltip-bottom-right .pl-tooltip-arrow {
  border-width: 0 4px 4px;
  border-bottom-color: #181818;
}
#html5video #hlsplayer .buttons-bar .autoplay-btn {
  display: inline-block;
  position: relative;
}
#html5video #hlsplayer .buttons-bar .autoplay-btn[disabled] .autoplay-bar-bt {
  opacity: 0.25;
  cursor: not-allowed;
}
#html5video #hlsplayer .buttons-bar .autoplay-btn .autoplay-bar-bt {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#html5video #hlsplayer .buttons-bar .autoplay-btn .autoplay-bar-bt .toggle-background-bar {
  height: 0.625em;
  width: 1.5em;
  border-radius: 0.5em;
  background: rgba(255, 255, 255, 0.3);
}
#html5video #hlsplayer .buttons-bar .autoplay-btn .autoplay-bar-bt.autoplay-on .toggle-background-bar {
  background: rgba(255, 255, 255, 0.3);
}
#html5video #hlsplayer .buttons-bar .autoplay-btn .autoplay-bar-bt .toggle-circle {
  height: 0.8333em;
  aspect-ratio: 1;
  background: rgba(51, 51, 51, 0.9);
  box-shadow: 0 0 3px #000;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transition: transform 0.2s;
  transform: translateY(-50%) translateX(-50%);
}
#html5video #hlsplayer .buttons-bar .autoplay-btn .autoplay-bar-bt .toggle-circle .player-icon-f {
  aspect-ratio: 1;
  color: rgba(255, 255, 255, 0.9);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.5em;
}
#html5video #hlsplayer .buttons-bar .autoplay-btn .autoplay-bar-bt.autoplay-on .toggle-circle {
  transform: translateY(-50%) translateX(50%);
  background: rgba(255, 255, 255, 0.9);
  background: var(--plyr-autoplay-act-bg, rgba(255, 255, 255, 0.9));
}
#html5video #hlsplayer .buttons-bar .autoplay-btn .autoplay-bar-bt.autoplay-on .toggle-circle .player-icon-f {
  color: rgba(22, 22, 22, 0.9);
  color: var(--plyr-autoplay-act-color, rgba(22, 22, 22, 0.9));
}
#html5video #hlsplayer .buttons-bar .settings-btn {
  display: inline-block;
  position: relative;
}
#html5video #hlsplayer .buttons-bar .settings-btn .video-hd-mark {
  position: absolute;
  line-height: inherit;
  padding: 1px 2px;
  height: auto;
  bottom: calc(50% + 2px);
  left: calc(50% + 2px);
  background: #DE2600;
  color: #fff;
  font-weight: bold;
  text-align: center;
  vertical-align: text-top;
}
#html5video #hlsplayer .buttons-bar .settings-btn .pif-settings {
  transition: transform 0.1s;
}
#html5video #hlsplayer .buttons-bar .settings-btn.is-open .pif-settings {
  transform: rotate(60deg);
}
#html5video #hlsplayer .settings-menu {
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(20px);
  border-radius: 16px;
  font-weight: normal;
  margin: 0;
  max-width: 96%;
  padding: 0;
  position: absolute;
  z-index: 200;
  overflow: hidden;
}
#html5video #hlsplayer .settings-menu.is-hidden-param {
  max-height: 0;
  opacity: 0;
  transition: max-height 0s 0.6s, opacity 0.3s ease-in-out;
}
@keyframes showVideoSettingsMenu {
  0% {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
  }
  50% {
    opacity: 1;
    overflow: hidden;
  }
  99% {
    max-height: 30em;
    max-height: var(--plyr-stng-maxh, 30em);
    overflow: hidden;
  }
  100% {
    overflow: visible;
  }
}
#html5video #hlsplayer .settings-menu.is-visible-param {
  animation: showVideoSettingsMenu 0.5s ease-in-out;
  animation-duration: var(--plyr-stng-anim, 0.5s);
  max-height: 30em;
  max-height: var(--plyr-stng-maxh, 30em);
  opacity: 1;
  overflow: auto;
}
#html5video #hlsplayer .settings-menu .setting {
  align-items: center;
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  line-height: 1.5em;
  margin: 0.35em 0.7em;
  overflow: hidden;
  padding: 0.3em 0.7em;
  position: relative;
  text-align: left;
  white-space: nowrap;
}
#html5video #hlsplayer .settings-menu .setting > * {
  flex-shrink: 1;
}
#html5video #hlsplayer .settings-menu .setting:first-child {
  margin-top: 1em;
}
#html5video #hlsplayer .settings-menu .setting:last-child {
  margin-bottom: 1em;
}
#html5video #hlsplayer .settings-menu .setting:hover,
#html5video #hlsplayer .settings-menu .setting.active {
  background-color: rgba(255, 255, 255, 0.08);
}
#html5video #hlsplayer .settings-menu .setting.forced {
  background-color: rgba(255, 255, 255, 0.1);
  cursor: default;
}
#html5video #hlsplayer .settings-menu .setting.title {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin: 0 0 0.7em;
  padding: 0.7em 2.5em;
  line-height: 1.8em;
}
#html5video #hlsplayer .settings-menu .setting.title .label .player-icon-f {
  font-size: 1.9em;
  line-height: 0.79em;
}
#html5video #hlsplayer .settings-menu .setting.back-link {
  padding-left: 3em;
}
#html5video #hlsplayer .settings-menu .setting.back-link:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'playericonfont' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e900";
  display: inline-block;
  font-size: 1.6em;
  position: absolute;
  left: 0.35em;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  width: 1em;
}
#html5video #hlsplayer .settings-menu .setting.has-sub-menu {
  padding-right: 3em;
}
#html5video #hlsplayer .settings-menu .setting.has-sub-menu:after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'playericonfont' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e901";
  display: inline-block;
  font-size: 1.6em;
  position: absolute;
  right: 0.35em;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  width: 1em;
}
#html5video #hlsplayer .settings-menu .setting .label {
  align-items: center;
  display: flex;
  position: relative;
  width: 100%;
}
#html5video #hlsplayer .settings-menu .setting .label > * {
  flex-shrink: 1;
}
#html5video #hlsplayer .settings-menu .setting .label .label-text {
  height: 1.5em;
  line-height: 1.5em;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
#html5video #hlsplayer .settings-menu .setting .label .label-text .val {
  color: #A3A3A3;
  margin-left: 2em;
  float: right;
}
#html5video #hlsplayer .settings-menu .setting .label .player-icon-f {
  flex-shrink: 0;
  font-size: 2em;
  height: 1em;
  line-height: 1em;
  width: 1em;
  margin-right: 0.5em;
  text-align: center;
  /*position: absolute;
						left: 0;
						top: 50%;
						transform: translateY(-50%);
						
						& + .label-text {
							padding-left: 2.5em;
						}
						*/
}
#html5video #hlsplayer .settings-menu .setting .label .player-icon-f.pif-loop {
  font-size: 1.6em;
  width: 1.25em;
  height: 1.25em;
  line-height: 1.25em;
}
#html5video #hlsplayer .settings-menu .setting .setting-checkbox {
  display: block;
  flex-shrink: 0;
  font-size: 1.5em;
  height: 1em;
  margin: 0 0 0 0.3em;
  line-height: 0.66em;
  position: relative;
  width: 1em;
}
#html5video #hlsplayer .settings-menu .setting .setting-checkbox:before,
#html5video #hlsplayer .settings-menu .setting .setting-checkbox:after {
  content: "";
  position: absolute;
  border-radius: 50%;
}
#html5video #hlsplayer .settings-menu .setting .setting-checkbox:before {
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;
  background: #DE2600;
  background: var(--plyr-checkbox-bg, #DE2600);
  z-index: 1;
  transform: scale(0);
  transition: transform 0.25s;
}
#html5video #hlsplayer .settings-menu .setting .setting-checkbox.is-checked:before {
  transform: scale(1);
}
#html5video #hlsplayer .settings-menu .setting .setting-checkbox:after {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 3px solid #fff;
  z-index: 2;
}
#html5video #hlsplayer .video-subscribe {
  display: inline-block;
  border: 1px solid #ddd;
  background: #f00;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
  font-size: 13px;
  line-height: 15px;
}
#html5video #hlsplayer .video-subscribe .sub-btn {
  display: inline-block;
  background: #fff;
  color: #f00;
  padding: 0 3px 0 4px;
  margin: 2px;
  line-height: 13px;
  border-radius: 1px;
}
#html5video #hlsplayer .video-subscribe .sub-btn .icon {
  margin: 1px 0px;
  vertical-align: bottom;
}
#html5video #hlsplayer .video-subscribe .nb-subscribers {
  display: inline-block;
  background: #fff;
  color: #000;
  padding: 1px 2px;
  font-weight: normal;
  margin-left: 1px;
}
#html5video #hlsplayer .video-subscribe a {
  color: #fff;
}
.notouch #html5video #hlsplayer .video-subscribe:hover {
  background: #DE2600;
}
.notouch #html5video #hlsplayer .video-subscribe:hover .sub-btn {
  color: #DE2600;
}
#html5video #hlsplayer .sponsor-popup {
  background-color: #000000;
  color: #fff;
  text-align: center;
  position: absolute;
  left: 10%;
  width: 80%;
  padding: 4px;
}
#html5video #hlsplayer .sponsor-popup a {
  color: #fff;
  text-decoration: underline;
}
#html5video #hlsplayer .sponsor-popup p {
  margin: 0;
  padding: 0;
}
#html5video #hlsplayer .sponsor-popup .sponsor-desc {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  font-weight: normal;
}
#html5video #hlsplayer .sponsor-popup .sponsor-click {
  position: absolute;
  top: 10%;
  left: 3%;
  right: 10%;
  bottom: 10%;
}
#html5video #hlsplayer .sponsor-popup .dlg-close-btn {
  position: absolute;
  top: 0;
  right: -5px;
  background: #000000;
}
#html5video #hlsplayer .related-vids {
  position: absolute;
  z-index: 3;
}
#html5video #hlsplayer .related-vids .related {
  position: relative;
  overflow: hidden;
}
#html5video #hlsplayer .related-vids.desktop-view .related-video .thumb a .title {
  font-size: 16px;
}
#html5video #hlsplayer .related-vids .related-video {
  color: #fff;
  box-sizing: border-box;
  display: inline-block;
  padding: 4px;
  position: relative;
  text-align: center;
  vertical-align: top;
  z-index: 1;
}
#html5video #hlsplayer .related-vids .related-video .thumb {
  bottom: 4px;
  left: 4px;
  position: absolute;
  right: 4px;
  top: 4px;
  width: auto;
}
#html5video #hlsplayer .related-vids .related-video .thumb a {
  bottom: 0;
  color: #fff;
  display: block;
  left: 0;
  overflow: visible;
  position: absolute;
  right: 0;
  text-decoration: none;
  top: 0;
}
#html5video #hlsplayer .related-vids .related-video .thumb a img {
  display: block;
  height: 100%;
  object-fit: cover;
  width: 100%;
}
#html5video #hlsplayer .related-vids .related-video .thumb a .duration {
  background: rgba(0, 0, 0, 0.7);
  bottom: 1px;
  color: #fff;
  font-size: 13px;
  font-weight: bold;
  opacity: 0;
  padding: 2px;
  position: absolute;
  right: 1px;
  text-decoration: none;
}
#html5video #hlsplayer .related-vids .related-video .thumb a .ad {
  position: absolute;
  left: 4px;
  bottom: 4px;
  background: #DE2600;
  color: #fff;
  font-weight: bold;
  border-radius: 3px;
  height: 21px;
  line-height: 23px;
  font-size: 16px;
  padding: 0 5px;
}
#html5video #hlsplayer .related-vids .related-video .thumb a .title {
  background: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  font-weight: normal;
  left: 0;
  margin: 0;
  opacity: 0;
  padding: 4px 6px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
}
#html5video #hlsplayer .related-vids .related-video .thumb .progress {
  background: #000;
  bottom: 0;
  left: 0;
  height: 2px;
  margin-bottom: 0;
  position: absolute;
  right: 0;
  width: 100%;
}
#html5video #hlsplayer .related-vids .related-video .thumb .progress .bar {
  background: #999;
  height: 2px;
  width: 0;
}
#html5video #hlsplayer .related-vids .related-video.one-line .thumb {
  background: #000;
}
#html5video #hlsplayer .related-vids .related-video.one-line .thumb > a {
  min-height: 81%;
  bottom: 18%;
}
#html5video #hlsplayer .related-vids .related-video.one-line .thumb > a .duration {
  opacity: 1;
}
#html5video #hlsplayer .related-vids .related-video.one-line .thumb > a .title {
  line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  white-space: normal;
  opacity: 1;
  padding: 0 6px;
  top: 112.5%;
  transform: translateY(-50%);
}
#html5video #hlsplayer .related-vids .related-video.rotator-started {
  z-index: 2;
}
#html5video #hlsplayer .related-vids .related-video.rotator-started .thumb a .duration,
#html5video #hlsplayer .related-vids .related-video.rotator-started .thumb a .title {
  opacity: 1;
}
#html5video #hlsplayer .related-vids .related-video:not(.one-line).rotator-started .thumb .videopv {
  bottom: 0;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
#html5video #hlsplayer .related-vids .related-video:not(.one-line).rotator-started .thumb img,
#html5video #hlsplayer .related-vids .related-video:not(.one-line).rotator-started .thumb .videopv {
  box-shadow: -1px -1px 0 1px #999, 1px -1px 0 1px #999;
}
#html5video #hlsplayer .related-vids .related-video:not(.one-line).rotator-started .thumb > a .title {
  background: #000;
  box-shadow: -1px 1px 0 1px #999, 1px 1px 0 1px #999;
  color: #fff;
  font-weight: bold;
  padding: 10px;
  top: 100%;
}
#html5video #hlsplayer .related-vids .related-video:not(.one-line).bottom-line.rotator-started .thumb .videopv {
  bottom: 1px;
}
#html5video #hlsplayer .related-vids .related-video:not(.one-line).bottom-line.rotator-started .thumb img,
#html5video #hlsplayer .related-vids .related-video:not(.one-line).bottom-line.rotator-started .thumb .videopv {
  box-shadow: -1px 1px 0 1px #999, 1px 1px 0 1px #999;
}
#html5video #hlsplayer .related-vids .related-video:not(.one-line).bottom-line.rotator-started .thumb > a .title {
  bottom: 100%;
  box-shadow: -1px -1px 0 1px #999, 1px -1px 0 1px #999;
  top: auto;
}
#html5video #hlsplayer .related-vids .related-video .border {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.7);
  border: 1px #888 solid;
  max-height: 100%;
  overflow: hidden;
}
#html5video #hlsplayer .related-vids .related-video .sponsor a {
  text-decoration: underline;
}
#html5video #hlsplayer .related-vids .related-video .sponsor-desc {
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 90%;
  font-weight: normal;
  text-align: center;
}
#html5video #hlsplayer .pl-next {
  position: absolute;
  width: 50%;
  background: #fff;
  box-shadow: 0 0 0 4px black;
  padding: 4px;
  max-width: 600px;
  min-width: 400px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
#html5video #hlsplayer .pl-next .thumb {
  width: 100%;
}
#html5video #hlsplayer .pl-next .loader,
#html5video #hlsplayer .pl-next .pl-timer {
  position: absolute;
  left: 40%;
  top: 40%;
  color: #fff;
  font-weight: bold;
}
#html5video #hlsplayer .pl-next p {
  position: absolute;
  left: 5px;
  top: 5px;
  max-width: 90%;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-weight: bold;
  text-align: left;
}
#html5video #hlsplayer .pl-next .dlg-close-btn {
  position: absolute;
  top: -20px;
  right: -20px;
  background: rgba(0, 0, 0, 0.5);
}
#html5video #hlsplayer.pl-mobile .pl-next {
  width: 70%;
}
#html5video #hlsplayer.pl-mobile-small .pl-next {
  width: 80%;
}
#html5video #hlsplayer.desktop .progress-bar .seek-cursor {
  bottom: 150%;
}
#html5video #hlsplayer.desktop .related-vids .related {
  margin: auto;
  text-align: center;
  overflow: hidden;
  position: static;
}
#html5video #hlsplayer.desktop .slowseek-info .label {
  font-size: 18px;
  line-height: 24px;
}
#html5video #hlsplayer.desktop .slowseek-info::after {
  top: 12px;
}
#html5video #hlsplayer.desktop .slowseek-info::before {
  top: 15px;
}
#html5video.desktop #hlsplayer .slowseek-info .label {
  font-size: 18px;
  line-height: 24px;
}
#html5video.desktop #hlsplayer .slowseek-info .label::after {
  top: 12px;
}
#html5video.desktop #hlsplayer .slowseek-info .label::before {
  top: 15px;
}
#html5video .pl-msg-box,
#page.video-page #html5video .pl-msg-box,
.pl-msg-box {
  box-sizing: border-box;
  font-size: 14px;
  font-weight: normal;
  color: #000;
}
#html5video .pl-msg-box *,
#page.video-page #html5video .pl-msg-box *,
.pl-msg-box * {
  box-sizing: border-box;
}
#html5video .pl-msg-box h2,
#page.video-page #html5video .pl-msg-box h2,
.pl-msg-box h2 {
  font-weight: bold;
  line-height: 26px;
  font-size: 18px;
  margin: 0 0 10px;
  background: none;
  color: #000;
}
#html5video .pl-msg-box h3,
#page.video-page #html5video .pl-msg-box h3,
.pl-msg-box h3 {
  font-weight: bold;
  line-height: 18px;
  font-size: 15px;
  margin: 0 0 10px;
}
.pl-msg-box {
  padding: 5px 15px 10px;
  text-align: center;
  border: 2px solid #000;
  background: #fff;
}
.pl-msg-box p {
  font-weight: normal;
}
.pl-msg-box .pl-error {
  background: #ffe4de;
  color: #DE2600;
  border-left: 4px solid #ff8f78;
  padding: 5px 15px;
  margin: 5px 0;
  font-size: 13px;
  font-weight: bold;
  text-align: left;
}
.pl-dl-form input[type="text"],
.pl-dl-form input[type="password"],
.pl-dl-form button {
  width: 100%;
  display: block;
  background: #fff;
  border: 1px solid #999;
  border-radius: 2px;
  height: 24px;
  padding: 2px 8px;
  margin: 0 0 3px;
  font-size: 13px;
  font-weight: bold;
  line-height: 1.5;
  box-shadow: none;
  color: #222;
}
.pl-dl-form button {
  color: #fff;
  background: #000;
  border-color: #000;
  font-size: 16px;
  height: 28px;
  margin: 5px 0 0;
}
.notouch .pl-dl-form button:hover {
  background: #222;
}
.pl-dl-form .pl-login {
  display: block;
  width: 50%;
  float: left;
  border-right: 2px solid #ccc;
  padding-right: 15px;
}
.pl-dl-form .pl-login .pl-forgot-pwd {
  margin-top: 10px;
}
.pl-dl-form .pl-login .pl-forgot-pwd a {
  font-weight: normal;
  color: #000;
}
.pl-dl-form .pl-login .pl-tos {
  display: none;
  text-align: left;
}
.pl-dl-form .pl-login .pl-tos input {
  vertical-align: middle;
  padding-right: 5px;
}
.pl-dl-form .pl-login .pl-tos label {
  font-weight: bold;
  display: inline;
  font-size: 11px;
}
.pl-dl-form .pl-login .pl-tos label a {
  color: #000;
}
.pl-dl-form .pl-create-account {
  overflow: hidden;
  padding-left: 15px;
}
.pl-dl-form .pl-create-account .not-member {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 52px;
  font-weight: bold;
  font-style: italic;
}
.pl-dl-form .pl-create-account input[type="text"],
.pl-dl-form .pl-create-account input[type="password"] {
  display: none;
}
.pl-dl-form .pl-create-account .create-acct-slogan {
  margin-top: 10px;
  font-size: 11px;
  font-weight: bold;
  font-style: italic;
  line-height: 14px;
}
.pl-dl-form .pl-clear {
  clear: both;
}
.pl-dl-form .pl-footer {
  color: #111;
  font-style: italic;
  line-height: 16px;
  font-size: 13px;
  margin-top: 10px;
  text-align: left;
}
.pl-dl-form.pl-create .pl-login button,
.pl-dl-form.pl-create .pl-login .pl-forgot-pwd {
  display: none;
}
.pl-dl-form.pl-create .pl-login .pl-tos {
  display: block;
}
.pl-dl-form.pl-create .pl-create-account .not-member,
.pl-dl-form.pl-create .pl-create-account .create-acct-slogan {
  display: none;
}
.pl-dl-form.pl-create .pl-create-account input[type="text"],
.pl-dl-form.pl-create .pl-create-account input[type="password"] {
  display: block;
}
#html5video .pl-mobile .pl-dl-form,
#page.video-page #html5video .pl-mobile .pl-dl-form,
.pl-mobile .pl-dl-form {
  padding: 5px 10px 5px;
  font-size: 13px;
}
#html5video .pl-mobile .pl-dl-form h3,
#page.video-page #html5video .pl-mobile .pl-dl-form h3,
.pl-mobile .pl-dl-form h3 {
  line-height: 17px;
  font-size: 13px;
  margin-bottom: 2px;
}
#html5video .pl-mobile .pl-dl-form input[type="text"],
#page.video-page #html5video .pl-mobile .pl-dl-form input[type="text"],
.pl-mobile .pl-dl-form input[type="text"],
#html5video .pl-mobile .pl-dl-form input[type="password"],
#page.video-page #html5video .pl-mobile .pl-dl-form input[type="password"],
.pl-mobile .pl-dl-form input[type="password"],
#html5video .pl-mobile .pl-dl-form button,
#page.video-page #html5video .pl-mobile .pl-dl-form button,
.pl-mobile .pl-dl-form button {
  font-size: 13px;
  height: 24px;
}
#html5video .pl-mobile .pl-dl-form .pl-footer,
#page.video-page #html5video .pl-mobile .pl-dl-form .pl-footer,
.pl-mobile .pl-dl-form .pl-footer {
  line-height: 15px;
  font-size: 12px;
  margin-top: 5px;
}
#html5video .pl-mobile-small .pl-dl-form h3,
#page.video-page #html5video .pl-mobile-small .pl-dl-form h3,
.pl-mobile-small .pl-dl-form h3 {
  line-height: 15px;
  font-size: 12px;
  margin-bottom: 5px;
}
#html5video .pl-mobile-small .pl-dl-form input[type="text"],
#page.video-page #html5video .pl-mobile-small .pl-dl-form input[type="text"],
.pl-mobile-small .pl-dl-form input[type="text"],
#html5video .pl-mobile-small .pl-dl-form input[type="password"],
#page.video-page #html5video .pl-mobile-small .pl-dl-form input[type="password"],
.pl-mobile-small .pl-dl-form input[type="password"],
#html5video .pl-mobile-small .pl-dl-form button,
#page.video-page #html5video .pl-mobile-small .pl-dl-form button,
.pl-mobile-small .pl-dl-form button {
  padding: 1px 4px;
  font-size: 11px;
  height: 20px;
}
#html5video .pl-mobile-small .pl-dl-form .pl-login .pl-forgot-pwd,
#page.video-page #html5video .pl-mobile-small .pl-dl-form .pl-login .pl-forgot-pwd,
.pl-mobile-small .pl-dl-form .pl-login .pl-forgot-pwd {
  margin-top: 3px;
}
#html5video .pl-mobile-small .pl-dl-form .pl-login .pl-tos label,
#page.video-page #html5video .pl-mobile-small .pl-dl-form .pl-login .pl-tos label,
.pl-mobile-small .pl-dl-form .pl-login .pl-tos label {
  font-weight: normal;
}
#html5video .pl-mobile-small .pl-dl-form .pl-create-account .not-member,
#page.video-page #html5video .pl-mobile-small .pl-dl-form .pl-create-account .not-member,
.pl-mobile-small .pl-dl-form .pl-create-account .not-member {
  font-size: 11px;
  height: 44px;
}
#html5video .pl-mobile-small .pl-dl-form .pl-create-account .create-acct-slogan,
#page.video-page #html5video .pl-mobile-small .pl-dl-form .pl-create-account .create-acct-slogan,
.pl-mobile-small .pl-dl-form .pl-create-account .create-acct-slogan {
  margin-top: 3px;
}
#html5video .pl-mobile-small .pl-dl-form .pl-footer,
#page.video-page #html5video .pl-mobile-small .pl-dl-form .pl-footer,
.pl-mobile-small .pl-dl-form .pl-footer {
  line-height: 14px;
  font-size: 11px;
  margin-top: 3px;
}
#html5video .pl-mobile-small .pl-error,
#page.video-page #html5video .pl-mobile-small .pl-error,
.pl-mobile-small .pl-error {
  padding: 1px 5px;
  font-size: 11px;
}
.pl-dl-box .pl-dl-btns {
  overflow: hidden;
}
.pl-dl-box .pl-dl-btns a {
  display: block;
  text-decoration: none;
  background: #000;
  color: #fff;
  padding: 20px 10px;
  font-size: 20px;
}
.notouch .pl-dl-box .pl-dl-btns a:hover {
  background: #222;
}
.pl-dl-box .pl-dl-btns.two-btns .low-qual {
  float: left;
  width: 50%;
  border-right: 5px solid #fff;
}
.pl-dl-box .pl-dl-btns.two-btns .high-qual {
  border-left: 5px solid #fff;
}
.pl-mobile .pl-dl-box .pl-dl-btns a {
  font-size: 18px;
  padding: 10px;
}
.pl-mobile .pl-dl-box .pl-dl-btns.two-btns .low-qual {
  float: none;
  width: auto;
  border-right: none;
  margin-bottom: 5px;
}
.pl-mobile .pl-dl-box .pl-dl-btns.two-btns .high-qual {
  border-left: none;
}
.videoad-base {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 6;
}
.videoad-video {
  position: absolute;
  width: 100%;
  height: 100%;
}
.videoad-click {
  position: absolute;
  width: 100%;
  height: 100%;
}
.videoad-nocontrol-ios::-webkit-media-controls-play-button,
.videoad-nocontrol-ios::-webkit-media-controls-start-playback-button {
  opacity: 0;
  pointer-events: none;
  width: 5px;
}
.videoad-skip {
  background-color: #000;
  border: 2px #999 solid;
  border-color: rgba(255, 255, 255, 0.35);
  bottom: 0;
  color: #FFF;
  color: rgba(255, 255, 255, 0.8);
  cursor: default;
  height: 40px;
  line-height: 40px;
  margin: 5px;
  max-width: 90%;
  max-width: calc(95% - 24px);
  position: absolute;
  right: 0;
  transition: all 0.2s ease;
  border-radius: 5px;
}
.videoad-skip .player-icon-f {
  color: rgba(255, 255, 255, 0.5);
  transition: color 0.2s ease;
  margin-left: 3px;
  vertical-align: bottom;
}
#html5video #hlsplayer .videoad-skip .player-icon-f {
  color: rgba(255, 255, 255, 0.5);
}
.notouch #html5video #hlsplayer .videoad-skip.skippable:hover .player-icon-f {
  color: #FFF;
}
.notouch .videoad-skip {
  background-color: rgba(0, 0, 0, 0.6);
}
.notouch .videoad-skip:hover {
  background-color: #000;
}
.notouch .videoad-skip.skippable:hover {
  border-color: #FFF;
  color: #FFF;
  cursor: pointer;
}
.notouch .videoad-skip.skippable:hover .player-icon-f {
  color: #FFF;
}
.videoad-skip .videoad-skip-txt {
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  line-height: 18px;
  padding: 0 10px;
  white-space: nowrap;
}
.videoad-title {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  right: 0;
  padding: 10px 15px;
  position: absolute;
  text-align: left;
  top: 0;
  transition: all 0.2s ease;
}
.videoad-title,
.videoad-title:focus,
.videoad-title:active,
.videoad-title:hover {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.5);
  color: #FFF;
  cursor: pointer;
  text-decoration: none;
}
.videoad-title.videoad-title-beforevideo {
  background-color: rgba(216, 42, 30, 0.75);
  border: 2px #d03328 solid;
}
.notouch .videoad-title.videoad-title-beforevideo:hover {
  background-color: rgba(169, 36, 27, 0.95);
}
#centered-full-video-link {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  text-align: center;
  z-index: 2;
}
#centered-full-video-link a {
  background: black;
  border: 2px solid #fff;
  border-radius: 3px;
  color: white;
  display: inline-block;
  font-size: 20px;
  padding: 8px 14px;
  text-decoration: none;
}
#centered-full-video-link a:hover {
  background: #333;
}
#centered-full-video-link a .logo {
  display: block;
}
#centered-full-video-link a .thumb {
  border-radius: 10px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  display: block;
  margin: 4px auto 10px;
  max-width: 90%;
  width: 180px;
}
#centered-full-video-link a.is-sh {
  background: #eee;
  border: 0;
  border-radius: 50px;
  color: #333;
  padding: 20px 30px 16px;
}
#centered-full-video-link a.is-sh img.sheer {
  display: block;
  margin: 10px auto 0;
  max-width: 90%;
  width: 160px;
}
#centered-full-video-link a.is-sh::before,
#centered-full-video-link a.is-sh::after {
  border-radius: 50px;
  content: "";
  position: absolute;
}
#centered-full-video-link a.is-sh::before {
  background: linear-gradient(90deg, #51c8e9 40%, #893acc 120%);
  bottom: -3px;
  right: -3px;
  left: -3px;
  top: -3px;
  z-index: -2;
}
#centered-full-video-link a.is-sh::after {
  background: #eee;
  box-shadow: 0 0 5px inset rgba(0, 0, 0, 0.5);
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  z-index: -1;
}
#centered-full-video-link a.is-sh:hover::before {
  bottom: -4px;
  right: -4px;
  left: -4px;
  top: -4px;
}
#centered-full-video-link a.is-sh:hover::after {
  background: #fff;
  box-shadow: 0 0 3px inset rgba(0, 0, 0, 0.5);
  bottom: -1px;
  right: -1px;
  left: -1px;
  top: -1px;
}
.videoad-title-invideo {
  font-size: 12px;
  padding: 5px 10px;
  z-index: 5;
}
.videoad-title-txt {
  display: inline;
}
.videoad-title-icon,
.videoad-centerlink-icon {
  display: inline-block;
  font-size: 1.2em;
  line-height: 0.833em;
  margin-left: 6px;
}
.videoad-title-icon .player-icon-f,
.videoad-centerlink-icon .player-icon-f {
  display: inline-block;
  font-size: 1em;
  line-height: 0.833em;
  vertical-align: middle;
}
.videoad-centerlink-superdiv {
  position: absolute;
  width: 100%;
  text-align: center;
  margin: 10px;
}
.videoad-centerlink {
  background-color: rgba(216, 42, 30, 0.75);
  border-color: #d03328;
  border-radius: 5px;
  color: #FFF;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  bottom: 0;
  margin: 0;
  padding: 10px 15px;
  text-align: left;
  transition: all 0.2s ease;
}
.notouch .videoad-centerlink:hover {
  background-color: rgba(169, 36, 27, 0.95);
}
.videoad-centerlink-txt {
  display: inline;
}
.videoad-status {
  vertical-align: middle;
  color: #FFF;
  font-size: 12px;
  font-weight: normal;
  margin-left: 0.5em;
  left: 0;
  bottom: 0;
  height: 100%;
  display: inline;
}
.videoad-mute,
.videoad-unmute {
  border-radius: 50%;
  bottom: 0;
  cursor: pointer;
  display: block;
  height: 20px;
  left: 0;
  margin: 8px;
  padding: 15px;
  position: absolute;
  width: 20px;
}
.videoad-mute {
  animation: pulse 2s 1s ease-out;
  box-shadow: 0 0 0 rgba(255, 255, 255, 0.2);
}
.notouch .videoad-mute:hover {
  animation: pulse 2s ease-out infinite;
}
.videoad-unmute {
  display: none;
}
.muted .videoad-mute {
  animation: none;
  display: none;
}
.muted .videoad-unmute {
  display: block;
}
.progress-bar-cursor {
  height: 100%;
  background-color: #ccd232;
}
.info-button {
  background-color: #1c1c1c;
  border: 1px solid #555;
  color: white;
  padding: 1px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 10px;
  margin: 8px;
  cursor: pointer;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  line-height: 15px;
}
.info-button:hover {
  background-color: #4d4d4d;
  /* Couleur de fond au survol */
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.2);
    background: rgba(255, 255, 255, 0);
  }
  35% {
    background: rgba(255, 255, 255, 0.1);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    background: rgba(255, 255, 255, 0);
  }
}
@media (max-width: 480px) or (max-height: 480px) {
  #html5video #hlsplayer .settings-menu .setting {
    padding: 0.15em 0.5em;
  }
}
@media (max-width: 480px) {
  .videoad-title {
    font-size: 16px;
    padding: 8px 10px;
  }
  .videoad-title-invideo {
    font-size: 12px;
    padding: 5px 10px;
  }
  .videoad-skip {
    height: 30px;
    line-height: 28px;
    margin: 2px;
  }
  .videoad-skip .videoad-skip-txt {
    font-size: 15px;
    line-height: 17px;
  }
  .videoad-centerlink {
    font-size: 16px;
    padding: 8px 10px;
  }
  #html5video #hlsplayer.pl-mobile .pl-next {
    min-width: unset;
    width: 75%;
  }
}
@media (max-width: 360px) {
  .videoad-skip {
    height: 27px;
    line-height: 25px;
    margin: 1px;
  }
  .videoad-skip .videoad-skip-txt {
    font-size: 14px;
  }
  #html5video #hlsplayer.pl-mobile .pl-next {
    width: 80%;
  }
}
@media (max-width: 280px) {
  .videoad-skip {
    height: 25px;
    line-height: 23px;
  }
  .videoad-skip .videoad-skip-txt {
    font-size: 13px;
  }
}
