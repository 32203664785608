@player-icomoon-font-family: "playericonfont";
@player-icomoon-font-path: "fonts";

@import './playericonfont_chars.less';

@font-v-YY: '10'; //YY - year
@font-v-MM: '01'; //MM - month
@font-v-DD: '25'; //DD - day
@font-v-HH: '12'; //HH - time : hours
@font-v-mm: '36'; //mm - time : minutes

@font-version: "@{font-v-YY}@{font-v-MM}@{font-v-DD}@{font-v-HH}@{font-v-mm}";
@player-icomoon-font-family: "playericonfont";
@player-icomoon-font-path: '../../../../v-0@{font-version}/v3/fonts/skins/player/@{player-icomoon-font-family}/';
.font-family-playericonfont() {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: '@{player-icomoon-font-family}' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	
	/* Enable Ligatures ================ */
	letter-spacing: 0;
	-webkit-font-feature-settings: "liga";
	-moz-font-feature-settings: "liga=1";
	-moz-font-feature-settings: "liga";
	-ms-font-feature-settings: "liga" 1;
	font-feature-settings: "liga";
	-webkit-font-variant-ligatures: discretionary-ligatures;
	font-variant-ligatures: discretionary-ligatures;
	
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@font-face {
	font-family: '@{player-icomoon-font-family}';
	src: url('@{player-icomoon-font-path}/@{player-icomoon-font-family}.woff2') format('woff2'),
	url('@{player-icomoon-font-path}/@{player-icomoon-font-family}.ttf') format('truetype'),
	url('@{player-icomoon-font-path}/@{player-icomoon-font-family}.woff') format('woff'),
	url('@{player-icomoon-font-path}/@{player-icomoon-font-family}.svg#@{player-icomoon-font-family}') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

.player-icon-f {
	.font-family-playericonfont();
}

/* === ICONS START === */

.pif-angle-double-left {
	&:before {
		content: @pif-angle-double-left;
	}
}

.pif-angle-double-right {
	&:before {
		content: @pif-angle-double-right;
	}
}

.pif-angle-left {
	&:before {
		content: @pif-angle-left;
	}
}

.pif-angle-right {
	&:before {
		content: @pif-angle-right;
	}
}

.pif-chromecast {
	&:before {
		content: @pif-chromecast;
	}
}

.pif-close {
	&:before {
		content: @pif-close;
	}
}

.pif-download {
	&:before {
		content: @pif-download;
	}
}

.pif-external {
	&:before {
		content: @pif-external;
	}
}

.pif-feed {
	&:before {
		content: @pif-feed;
	}
}

.pif-full-screen {
	&:before {
		content: @pif-full-screen;
	}
}

.pif-full-width {
	&:before {
		content: @pif-full-width;
	}
}

.pif-hd {
	&:before {
		content: @pif-hd;
	}
}

.pif-info-circle {
	&:before {
		content: @pif-info-circle;
	}
}

.pif-internal {
	&:before {
		content: @pif-internal;
	}
}

.pif-link {
	&:before {
		content: @pif-link;
	}
}

.pif-loop {
	&:before {
		content: @pif-loop;
	}
}

.pif-pause {
	&:before {
		content: @pif-pause;
	}
}

.pif-play {
	&:before {
		content: @pif-play;
	}
}

.pif-quality {
	&:before {
		content: @pif-quality;
	}
}

.pif-repeat {
	&:before {
		content: @pif-repeat;
	}
}

.pif-save {
	&:before {
		content: @pif-save;
	}
}

.pif-settings {
	&:before {
		content: @pif-settings;
	}
}

.pif-settings-advanced {
	&:before {
		content: @pif-settings-advanced;
	}
}

.pif-sound-0 {
	&:before {
		content: @pif-sound-0;
	}
}

.pif-sound-1 {
	&:before {
		content: @pif-sound-1;
	}
}

.pif-sound-2 {
	&:before {
		content: @pif-sound-2;
	}
}

.pif-sound-mute {
	&:before {
		content: @pif-sound-mute;
	}
}

.pif-sound-mute-x {
	&:before {
		content: @pif-sound-mute-x;
	}
}

.pif-speed {
	&:before {
		content: @pif-speed;
	}
}

.pif-spinner {
	&:before {
		content: @pif-spinner;
	}
}

.pif-step-backward {
	&:before {
		content: @pif-step-backward;
	}
}

.pif-step-forward {
	&:before {
		content: @pif-step-forward;
	}
}


/* === ICONS END === */


// Colored
// -------------------------


.pif-sound-colored, // to have the same picto placement method on pif-sound-0 / 1 / 2
.pif-sound-mute-colored {
	box-sizing: content-box;
	display: inline-block;
	height: 1em;
	position: relative;
	width: 1em;
	
	&:after,
	&:before {
		position: absolute;
		display: inline-block;
		top: 0;
		left: 0;
		height: 1em;
		width: 1em;
	}
}

.pif-sound-mute-colored {
	&:before {
		content: @pif-sound-mute-x;
		color: #de2600;
	}
	&:after {
		content: @pif-sound-0;
	}
}


// White Background
// -------------------------
.pif-white-fill {
	position: relative;
	
	&:before {
		position: relative;
		z-index: 2;
	}
	
	&:after {
		background: white;
		bottom: 12%;
		content: " ";
		left: 12%;
		position: absolute;
		right: 12%;
		top: 12%;
		z-index: 1;
	}
	
	&.pif-hd {
		&:after {
			bottom: 27%;
			left: 14%;
			right: 14%;
			top: 24%;
		}
	}
}

.pif-full-screen-animated {
	display: inline-block;
	height: 1em;
	width: 1em;
	position: relative;
	
	span {
		height: 0.5em;
		overflow: hidden;
		position: absolute;
		transition: transform 0.1s ease-in-out;
		width: 0.5em;
		
		&:before {
			content: @pif-full-screen;
			display: inline-block;
			height: 1em;
			position: absolute;
			width: 1em;
		}
		
		//top left
		&:nth-child(1) {
			&,
			&:before  {
				left: 0;
				top: 0;
			}
		}
		
		//top right
		&:nth-child(2) {
			&,
			&:before  {
				right: 0;
				top: 0;
			}
		}
		
		//bottom left
		&:nth-child(3) {
			&,
			&:before  {
				bottom: 0;
				left: 0;
			}
		}
		
		//bottom right
		&:nth-child(4) {
			&,
			&:before  {
				bottom: 0;
				right: 0;
			}
		}
	}
	
	.notouch &:hover span {
		//top left
		&:nth-child(1) {
			transform: translate(-0.05em, -0.05em);
		}
		
		//top right
		&:nth-child(2) {
			transform: translate(0.05em, -0.05em);
		}
		
		//bottom left
		&:nth-child(3) {
			transform: translate(-0.05em, 0.05em);
		}
		
		//bottom right
		&:nth-child(4) {
			transform: translate(0.05em, 0.05em);
		}
	}
	
	.notouch:fullscreen &:hover span,
	.notouch *:fullscreen &:hover span {
		//top left
		&:nth-child(1) {
			transform: translate(0.05em, 0.05em);
		}
		
		//top right
		&:nth-child(2) {
			transform: translate(-0.05em, 0.05em);
		}
		
		//bottom left
		&:nth-child(3) {
			transform: translate(0.05em, -0.05em);
		}
		
		//bottom right
		&:nth-child(4) {
			transform: translate(-0.05em, -0.05em);
		}
	}
}
