@import "../../common/utils";
@import "./playericonfont";


@zindex-regular-items: 					1;
@zindex-centered-full-video-link : 		2;
@zindex-embedresponsiveitem-related : 	3;
@zindex-buttons-bar: 					4;
@zindex-embedresponsiveitem-topleft : 	4;
@zindex-sponsor-topright : 				5;
@zindex-ad: 							6;
@zindex-other-topleft : 				7;

@default-red-color : 					#DE2600;
@default-active-color : 				@default-red-color;

body {
	--plyr-autoplay-act-bg: fadeOut(#fff, 10%);
	--plyr-autoplay-act-color: fadeOut(#161616, 10%);
	--plyr-checkbox-bg: @default-active-color;
	--plyr-volume-active: @default-active-color;
}

#html5video {
	
	position: relative;
	
	&,
	* {
		box-sizing: content-box;
	}
	
	&.fakefullscreen {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 400;
	}
	
	.not-selectable() {
		-webkit-touch-callout: none; /* iOS Safari */
		-webkit-user-select: none; /* Chrome/Safari/Opera */
		-khtml-user-select: none; /* Konqueror */
		-moz-user-select: none; /* Firefox */
		-ms-user-select: none; /* Internet Explorer/Edge */
		user-select: none; /* Non-prefixed version, currently
									  not supported by any browser */
	}
	.noselect {
		.not-selectable;
	}
	
	.sdabox {
		position: absolute;
		height: 235px;
		width: 338px;
		background: #fff;
		border: 4px solid #000;
		padding: 4px;
		top: 5%;
		left: 10%;
		.sdacontainer {
			position: absolute;
			left: 4px;
			top: 4px;
			right: 4px;
			bottom: 4px;
			img {
				width: 100%;
				height: 100%;
			}
		}
		.dlg-close-btn {
			position: absolute;
			top: -4px;
			right: -40px;
			background: rgba(0, 0, 0, 0.5);
		}
		.sdaclick {
			position: absolute;
			&.sda10pc {
				left: 10%;
				top: 10%;
				right: 10%;
				bottom: 10%;
			}
			&.sda15pc {
				left: 15%;
				top: 15%;
				right: 15%;
				bottom: 15%;
			}
		}
		.sdadblclick {
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			display: block;
			width: 100%;
			height: 100%;
		}
	}
	
	#hlsplayer {
		overflow: hidden;
		
		& > * {
			z-index: @zindex-regular-items;
		}
		
		&.is-slow-seeking * {
			.not-selectable;
		}
		
		& > #centered-full-video-link {
			z-index: @zindex-centered-full-video-link;
		}
		& > .related-vids {
			z-index: @zindex-embedresponsiveitem-related;
		}
		& > .buttons-bar {
			z-index: @zindex-buttons-bar;
		}
		& > .top-left {
			z-index: @zindex-embedresponsiveitem-topleft;
		}
		& > .videoad-title-invideo {
			z-index: @zindex-sponsor-topright;
		}
		& > .videoad-base {
			z-index: @zindex-ad;
		}
		
		.video-bg-pic {
			width: 100%;
			height: 100%;
			background-color: #000;
			position: relative;
			.video-pic,
			.video-click-handler {
				position: absolute;
				width: 2000px;
				height: 100%;
				top: 0;
				left: 50%;
				margin: 0 -1000px;
			}
			.video-pic {
				background-color: #000;
				text-align: center;
				img {
					height: 100%;
					margin: 0 auto;
				}
				&.picture-related {
					img {
						display: none;
					}
				}
			}
		}
		video {
			width: 100%;
			height: 100%;
			background-color: #000;
			.ios_player::-webkit-media-controls-play-button,
			.ios_player::-webkit-media-controls-start-playback-button {
				opacity: 0;
				pointer-events: none;
				width: 5px;
				display: none !important;
				-webkit-appearance: none;
			}
		}
		
		.player-icon-f {
			color: fadeOut(#fff, 10%);
		}
		
		@keyframes fast_left_to_right {
			0% {
				left: -30px;
				opacity: 0;
			}
			50% {
				opacity: 1;
			}
			100% {
				left: calc(~"100% - 30px");
				opacity: 0;
			}
		}
		@keyframes fast_right_to_left {
			0% {
				right: -30px;
				opacity: 0;
			}
			50% {
				opacity: 1;
			}
			100% {
				right: calc(~"100% - 30px");
				opacity: 0;
			}
		}
		
		.fastforward-left,
		.fastforward-right {
			height: 50%;
			left: 5%;
			overflow: hidden;
			position: absolute;
			text-align: center;
			top: 25%;
			transition: background 0.5s ease;
			width: 25%;
			.player-icon-f,
			.fastforward-txt {
				border-radius: 6px;
				color: #fff;
				line-height: 60px;
				margin-top: -30px;
				opacity: 0;
				position: absolute;
				top: 50%;
			}
			.player-icon-f {
				font-size: 60px;
			}
			.fastforward-txt {
				background: rgba(0, 0, 0, 0.3);
				transition: all 0.5s ease;
				width: 100%;
				z-index: 2;
			}
			&.fastforward-visible {
				.fastforward-txt {
					opacity: 1;
				}
			}
		}
		
		.fastforward-left {
			&.fastforward-visible {
				.pif-angle-double-left {
					animation-duration: 1s;
					animation-timing-function: linear;
					animation-name: fast_right_to_left;
				}
			}
		}
		
		.fastforward-right {
			left: auto;
			right: 5%;
			&.fastforward-visible {
				.pif-angle-double-right {
					animation-duration: 1s;
					animation-timing-function: linear;
					animation-name: fast_left_to_right;
				}
			}
		}
		
		.slowseek-info {
			background: rgba(0, 0, 0, 0.6);
			left: 0;
			min-height: 26px;
			position: absolute;
			text-align: center;
			top: 0;
			right: 0;
			
			.label {
				color: #fff;
				font-size: 14px;
				font-weight: normal;
				line-height: 16px;
				display: inline-block;
				max-width: calc(~"100% - 55px");
				padding: 5px 10px 5px 45px;
				position: relative;
				vertical-align: top;
				
				@keyframes left_right_oscill {
					0% {
						transform: translateX(-8px);
					}
					25% {
						transform: translateX(-8px);
					}
					50% {
						transform: translateX(8px);
					}
					75% {
						transform: translateX(8px);
					}
					100% {
						transform: translateX(-8px);
					}
				}
				
				&::after {
					animation: left_right_oscill 3s ease;
					animation-iteration-count: infinite;
					background: #fff;
					border-radius: 50%;
					box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
					content: ' ';
					display: block;
					height: 10px;
					left: 15px;
					position: absolute;
					top: 8px;
					width: 10px;
					z-index: 2;
				}
				
				&::before {
					background: #aaa;
					border-radius: 2px;
					content: ' ';
					display: block;
					height: 4px;
					left: 5px;
					position: absolute;
					top: 11px;
					width: 30px;
					z-index: 1;
				}
			}
		}
		
		
		.video-loader {
			border-radius: 3px;
			position: absolute;
			width: 100px;
			height: 100px;
			background: rgba(0, 0, 0, 0.3);
			.buffer {
				border-radius: 3px;
				position: absolute;
				background: rgba(255, 255, 255, 0.3);
				height: 0;
				width: 100%;
				bottom: 0;
				left: 0;
				right: 0;
			}
			.label {
				line-height: 100%;
				color: #fff;
				font-weight: bold;
				text-align: center;
				.not-selectable();
			}
		}
		
		.error-dialog {
			background: #000;
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			z-index: 300;
			.error-content {
				display: flex;
				text-align: center;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				height: 100%;
				p {
					color: #fff;
					font-size: 24px;
					padding: 10px 10px 20px;
				}
				button {
					color: #fff;
					padding: 0px 20px;
					background: #111;
					border: 2px solid #888;
					border-radius: 5px;
					font-size: 20px;
					line-height: 35px;
					.notouch &:hover {
						background: #333;
					}
				}
			}
		}
		
		.message-dialog {
			position: absolute;
			width: 90%;
			height: 90%;
			top: 5%;
			left: 5%;
			.message-content {
				overflow-x: hidden;
				overflow-y: auto;
			}
			.dlg-close-btn {
				position: absolute;
				top: -20px;
				right: -20px;
				background: rgba(0, 0, 0, 0.5);
			}
		}
		
		.top-left {
			left: 1%;
			margin: 0;
			max-width: 98%;
			position: absolute;
			text-align: left;
			top: 1%;
			vertical-align: text-top;
			z-index: @zindex-embedresponsiveitem-topleft;
			
			.big-button {
				float: left;
			}
			
			.full-video-link {
				background: #fff;
				border-radius: 3px;
				color: #000;
				display: inline-block;
				margin-right: 4px;
				padding: 0 6px;
				text-decoration: none;
			}
		}
		
		.video-title {
			background-color: rgba(0, 0, 0, 0.5);
			color: #fff;
			display: inline-block;
			margin: 0;
			overflow: hidden;
			text-align: left;
			text-overflow: ellipsis;
			white-space: nowrap;
			.playlist {
				font-weight: normal;
				.label {
					font-style: italic;
				}
			}
			.prefix {
				margin-right: 5px;
			}
			.full-video-link {
				background: #fff;
				border-radius: 3px;
				color: #000;
				display: inline-block;
				margin-right: 6px;
				padding: 0 6px;
				text-decoration: none;
				vertical-align: text-bottom;
			}
		}

		.commercial-com {
			display: flex;
			align-items: center;
    		width: fit-content;
			gap: 3px;
			padding: 4px;

			font-size: 55%;
			color: white;
			background-color: rgba(0,0,0,.5);
		}
		
		.video-ended-desktop {
			button {
				border: 0;
				display: inline-block;
				font-size: 12px;
				font-weight: bold;
				line-height: 14px;
				padding: 6px 8px;
				margin: 0 4px 0 0;
				text-transform: capitalize;
				vertical-align: top;
				
				&, &.active, &:active, &:focus {
					color: #fff;
					background: #262626;
				}
				* {
					display: inline-block;
					float: none;
					font-size: 14px;
					line-height: 14px;
					padding: 0;
					vertical-align: top;
				}
				
				.player-icon-f {
					padding-right: 4px;
				}
			}
		}

		.browser-controls-dl-btn {
			position: absolute;
			top: 1%;
			right: 1%;
		}
		
		.xv-logo {
			position: absolute;
			//bottom: 22%;
			//left: 10%;
		}
		
		.quality-btn {
			text-align: center;
			cursor: pointer;
			z-index: 200;
			position: absolute;
			bottom: 50%;
			right: 1%;
		}
		.quality-default {
			color: #fff;
			background-color: rgba(0, 0, 0, 0.5);
		}
		.quality-auto {
			color: #000;
			background-color: rgba(150, 150, 150, 0.7);
		}
		.quality-forced {
			color: #000;
			background-color: rgba(255, 255, 255, 0.7);
			font-weight: bold;
		}

		.big-buttons {
			position: absolute;
			//overflow: hidden;
			white-space: nowrap;

			&.big-buttons-center {
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
			}
		}
		.big-button {
			cursor: pointer;
			display: inline-block;
			text-align: center;
			vertical-align: top;
			img {
				background: rgba(0, 0, 0, 0.5);
			}
			&.pause,
			&.mute {
				opacity: 0.3;
				img {
					background: rgba(0, 0, 0, 0.8);
				}
			}
			&.pl-btn {
				img {
					background: rgba(0, 0, 0, 0.5);
				}
			}
			&.replay {
				img {
					background: rgba(0, 0, 0, 0.75);
				}
			}
			span {
				&:not(.player-icon-f) {
					color: #fff;
					font-weight: bolder;
					font-size: 14px;
					text-shadow: 1px 1px 2px black, 0 0 0.5em black, 0 0 0.1em black;
				}
				&.player-icon-f {
					display: inline-block;
					text-shadow: 0.03em 0.03em 0.1em black, 0 0 0.5em fadeOut(black,50%), 0 0 1em fadeOut(black,50%);
					
					& + span:not(.player-icon-f) {
						display: block;
					}
				}
			}
			.notouch & {
				transition: transform 0.1s;
				&:hover {
					transform: scale(1.1);
				}
				
				&.has-clickable-effect {
					transition: transform 0.1s ease, filter 0.1s ease;
					&.player-icon-f {
						transition: text-shadow 0.1s ease;
					}
					&:active {
						transform: translateY(0.1em);
						filter: brightness(0.95);
						
						.player-icon-f {
							text-shadow: 0.01em 0.01em 0.08em black, 0 0 0.5em fadeOut(black,60%), 0 0 0.12em fadeOut(black, 70%);
						}
					}
					&:hover {
						&:active {
							transform: scale(1.1) translateY(0.1em);
						}
					}
				}
			}
		}
		&.play-clicked {
			.big-button.play,
			.big-button.pl-btn {
				opacity: 0.7;
			}
		}
		&.paused {
			.big-button.pl-btn {
				opacity: 0.3;
				img {
					background: rgba(0, 0, 0, 0.8);
				}
			}
		}
		
		.progress-bar-bg {
			//background-color: rgba(0, 0, 0, 0.5);
			background: linear-gradient(to top, rgba(0,0,0,.7) 0%, rgba(0,0,0,.15) 60%, rgba(0,0,0,.0) 100%);
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
		}
		.progress-bar-container {
			position: absolute;
			.progress-text-mobile {
				color: #FFF;
				cursor: default;
				position: absolute;
				white-space: nowrap;
				//right: 0;
				//bottom: 0;
			}
		}
		.progress-bar {
			position: absolute;
			//background-color: #fff;
			background-color: rgba(254, 254, 254, 0.35);
			transition: height .2s;
			cursor: pointer;
			width: 100%;
			.bufferdiv {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
			}
			.buffer-elt {
				position: absolute;
				top: 0;
				height: 100%;
			}
			.cursor {
				position: absolute;
				height: 100%;
				width: 5px;
				top: 0;
				background-color: rgba(0, 0, 0, 0.7);
			}
			.roundcursor {
				background: #AAA;
				border-radius:50%;
				border: 1px solid #DDD;
				position: absolute;
				top: 0;
				box-shadow: 2px 2px 12px #555;
			}
			.progress-text {
				text-align: center;
				font-size: 20px;
				font-weight: bold;
				color: #FFF;
				cursor: default;
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
			}
			.pgbar-cursor-detect {
				position: absolute;
				width: 100%;
				left: 0;
				bottom: -5px;
			}
			.seek-cursor {
				z-index: 150;
				position: absolute;
				bottom: 100%;
				.seek-thumb {
					position: relative;
					width: 180px;
					height: 135px;
					background: #000 0 0 no-repeat;
					border: 1px solid #fff;
				}
				.seek-text {
					position: absolute;
					left: 10px;
					bottom: 0;
					font-size: 20px;
					color: #fff;
					font-weight: bold;
					text-shadow: 2px 0 0 #000, -2px 0 0 #000, 0 2px 0 #000, 0 -2px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000;
					cursor: default;
				}
				.fake-cursor {
					height: 10px;
					width: 5px;
					margin: 1px auto;
					background: rgba(0, 0, 0, 0.7);
					border: 1px solid #fff;
				}
				.hide-cursor {
					height: 10px;
					width: 5px;
				}
				
			}
			.fake-cursor-desktop-container {
				height: 10px;
				width: 5px;
				z-index: 150;
				position: absolute;
				.fake-cursor-desktop {
					height: 8px;
					width: 3px;
					margin: 0px auto;
					background: rgba(0, 0, 0, 0.7);
					border-left: 1px solid #fff;
					border-right: 1px solid #fff;
				}
			}
		}
		
		
		.has-clickable-effect {
			transition: transform 0.1s ease, filter 0.1s ease;
			&:active {
				transform: scale(0.85);
				filter: brightness(0.8);
			}
		}
		
		.buttons-bar {
			bottom: 0;
			position: absolute;
			z-index: @zindex-buttons-bar;
			.player-icon-f {
				display: inline-block;
				
				&:before {
					vertical-align: top;
				}
			}
			&.left {
				left: 0;
			}
			&.right {
				right: 0;
			}
			&.topleft {
				left: 0;
				top: 0;
				bottom: auto;
				z-index: @zindex-other-topleft;
			}
			> * {
				vertical-align: middle;
				cursor: pointer;
				display: inline-block;
			}
			.subscribe {
				display: inline-block;
			}
			.volume-bar {
				transition: width 0.2s, margin 0.2s;
				
				&,
				.volume-bar-fill {
					display: inline-block;
					position: relative;
					
					&:before {
						content: "";
						left: 50%;
						transform: translate(-50%, -50%);
						position: absolute;
						top: 50%;
						z-index: 1;
					}
				}
				
				&:before {
					background: fadeOut(#fff, 10%);
					border-radius: 0.1em;
					height: 0.2em;
					width: 98%;
				}
				.volume-bar-fill {
					float: left;
					width: 100%;
					height: 100%;
					
					&:before {
						background: @default-active-color;
						background: var(--plyr-volume-active, @default-active-color);
						border-radius: 0.125em;
						height: 0.25em;
						width: 100%;
					}
					
					&:after {
						background: @default-active-color;
						background: var(--plyr-volume-active, @default-active-color);
						box-shadow: 0 0 2px #000;
						border-radius: 50%;
						content: "";
						height: 0.5em;
						width: 0.5em;
						right: -0.25em;
						top: 50%;
						position: absolute;
						transition: font-size 0.15s;
						transform: translateY(-50%);
						z-index: 2;
					}
				}
				
				&.is-hidden-param {
					display: none;
				}
				
				&:not(:hover) {
					.volume-bar-fill {
						&:after {
							font-size: 0;
						}
					}
				}
				
				&.is-muted {
					width: 0 !important;
					margin-left: 0 !important;
					margin-right: 0 !important;
					&, &:not(:hover) {
						.volume-bar-fill {
							&:after {
								font-size: 0;
							}
						}
					}
				}
				
				& + .progress-text {
					margin-left: 1em;
				}
			}
			.progress-text {
				display: inline-block;
				font-size: 12px;
				color: #fff;
				font-weight: normal;
			}
			.pl-tooltip {
				position: absolute;
				display: flex;

				flex-direction: column;
				&.pl-tooltip-bottom-left,
				&.pl-tooltip-bottom-right {
					flex-direction: column-reverse;
				}

				&.pl-tooltip-fadeout {
					opacity: 0;
				}

				&.pl-tooltip-top-left {
					top: 0;
					left: 0;
					transform: translateY(-100%);
				}
				&.pl-tooltip-top-right {
					top: 0;
					right: 0;
					transform: translateY(-100%);
					align-items: flex-end;
				}
				&.pl-tooltip-bottom-left {
					bottom: 0;
					left: 0;
					transform: translateY(100%);
				}
				&.pl-tooltip-bottom-right {
					bottom: 0;
					right: 0;
					transform: translateY(100%);
					align-items: flex-end;
				}

				.pl-tooltip-text {
					display: flex;
					gap: 5px;
					align-items: flex-start;
					width: fit-content;
					background: rgb(24, 24, 24);
					color: #fff;
					font-size: 12px;
					padding: 4px 8px;
					text-align: left;

					.pl-tooltip-link {
						color: #fff;
						text-decoration: none;
						text-align: center;
						overflow-wrap: normal;
						padding: 7px;

						&:hover {
							color: #ccc;
						}
						.player-icon-f {
							font-size: 1.4em;
							vertical-align: top;
							line-height: 0.714em;
							display: inline-block;
							margin: 0 0.2em;
						}
					}
				}
				.pl-tooltip-arrow {
					border-color: transparent;
					border-style: solid;

					width: 0;
					height: 0;
				}
				&.pl-tooltip-top-left,
				&.pl-tooltip-top-right {
					.pl-tooltip-arrow {
						border-width: 4px 4px 0;
						border-top-color: rgb(24, 24, 24);
					}
				}
				&.pl-tooltip-bottom-left,
				&.pl-tooltip-bottom-right {
					.pl-tooltip-arrow {
						border-width: 0 4px 4px;
						border-bottom-color: rgb(24, 24, 24);
					}
				}
			}
			.autoplay-btn {
				display: inline-block;
				position: relative;

				&[disabled] .autoplay-bar-bt {
					opacity: 0.25;
					cursor: not-allowed;
				}

				.autoplay-bar-bt {
					width: 100%;
					height: 100%;

					display:flex;
					justify-content: center;
					align-items: center;

					.toggle-background-bar {
						height: 0.625em;
						width: 1.5em;
						border-radius: 0.5em;
						background: fadeOut(#fff, 70%);
					}
					&.autoplay-on .toggle-background-bar {
						background: fadeOut(#fff, 70%);
					}

					.toggle-circle {
						height: 0.8333em;
						aspect-ratio: 1/1;
						
						background: fadeOut(#333, 10%);
						box-shadow: 0 0 3px #000;

						border-radius: 50%;
						position: absolute;
						top: 50%;
						transition: transform 0.2s;

						// Off state
						transform: translateY(-50%) translateX(-50%);
						
						.player-icon-f {
							aspect-ratio: 1/1;
							color: fadeOut(#fff, 10%);

							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							font-size: 0.5em;
						}
					}

					&.autoplay-on .toggle-circle {
						transform: translateY(-50%) translateX(50%);
						background: fadeOut(#fff, 10%);
						background: var(--plyr-autoplay-act-bg, fadeOut(#fff, 10%));
						
						.player-icon-f {
							color: fadeOut(#161616, 10%);
							color: var(--plyr-autoplay-act-color, fadeOut(#161616, 10%));
						}
					}
				}
			}
			.settings-btn {
				display: inline-block;
				position: relative;
				.video-hd-mark {
					position: absolute;
					line-height: inherit;
					padding: 1px 2px;
					height: auto;
					bottom: calc(~"50% + 2px");
					left: calc(~"50% + 2px");
					background: @default-red-color;
					color: #fff;
					font-weight: bold;
					text-align: center;
					vertical-align: text-top;
				}
				
				.pif-settings {
					transition: transform 0.1s;
				}
				&.is-open {
					.pif-settings {
						transform: rotate(60deg);
					}
				}
			}
		}
		&.pref-loaded {
			.buttons-bar {
				.volume-bar {
				}
			}
		}
		
		.settings-menu {
			background: fadeOut(#000, 40%);
			backdrop-filter: blur(20px);
			border-radius: 16px;
			font-weight: normal;
			margin: 0;
			max-width: 96%;
			padding: 0;
			position: absolute;
			z-index: 200;
			
			overflow: hidden;
			
			&.is-hidden-param {
				max-height: 0;
				opacity: 0;
				transition: max-height 0s 0.6s, opacity 0.3s ease-in-out;
			}
			@keyframes showVideoSettingsMenu {
				0% {
					max-height: 0;
					opacity: 0;
					overflow: hidden;
				}
				50% {
					opacity: 1;
					overflow: hidden;
				}
				99% {
					max-height: 30em;
					max-height: var(--plyr-stng-maxh, 30em);
					overflow: hidden;
				}
				100% {
					overflow: visible;
				}
			}
			&.is-visible-param {
				animation: showVideoSettingsMenu 0.5s ease-in-out;
				animation-duration: var(--plyr-stng-anim, 0.5s);
				max-height: 30em;
				max-height: var(--plyr-stng-maxh, 30em);
				opacity: 1;
				overflow: auto;
			}
			
			.setting {
				align-items: center;
				display: flex;
				justify-content: space-between;
				border-radius: 8px;
				color: #fff;
				cursor: pointer;
				line-height: 1.5em;
				margin: .35em .7em;
				overflow: hidden;
				padding: 0.3em 0.7em;
				position: relative;
				text-align: left;
				white-space: nowrap;
				
				& > * {
					flex-shrink: 1;
				}
				
				&:first-child {
					margin-top: 1em;
				}
				&:last-child {
					margin-bottom: 1em;
				}
				
				&:hover,
				&.active {
					background-color: rgba(255, 255, 255, 0.08);
				}
				&.forced {
					background-color: rgba(255, 255, 255, 0.1);
					cursor: default;
				}
				&.title {
					border-bottom-left-radius: 0;
					border-bottom-right-radius: 0;
					border-bottom: 1px solid fadeOut(#fff, 90%);
					margin: 0 0 0.7em;
					padding: 0.7em 2.5em;
					line-height: 1.8em;
					
					.label {
						.player-icon-f {
							font-size: 1.9em;
							line-height: 0.79em;
						}
					}
				}
				&.back-link {
					padding-left: 3em;
					
					&:before {
						.font-family-playericonfont();
						content: @pif-angle-left;
						display: inline-block;
						font-size: 1.6em;
						position: absolute;
						left: 0.35em;
						text-align: center;
						top: 50%;
						transform: translateY(-50%);
						width: 1em;
					}
				}
				&.has-sub-menu {
					padding-right: 3em;
					
					&:after {
						.font-family-playericonfont();
						content: @pif-angle-right;
						display: inline-block;
						font-size: 1.6em;
						position: absolute;
						right: 0.35em;
						text-align: center;
						top: 50%;
						transform: translateY(-50%);
						width: 1em;
					}
				}
				
				.label {
					align-items: center;
					display: flex;
					position: relative;
					width: 100%;
					
					& > * {
						flex-shrink: 1;
					}
					
					.label-text {
						height: 1.5em;
						line-height: 1.5em;
						overflow: hidden;
						text-overflow: ellipsis;
						width: 100%;
						
						.val {
							color: #A3A3A3;
							margin-left: 2em;
							float: right;
						}
					}
					
					.player-icon-f {
						flex-shrink: 0;
						font-size: 2em;
						height: 1em;
						line-height: 1em;
						width: 1em;
						margin-right: 0.5em;
						text-align: center;
						
						
						/*position: absolute;
						left: 0;
						top: 50%;
						transform: translateY(-50%);
						
						& + .label-text {
							padding-left: 2.5em;
						}
						*/
						&.pif-loop {
							font-size: 1.6em;
							width: 1.25em;
							height: 1.25em;
							line-height: 1.25em;
						}
					}
				}
				
				.setting-checkbox {
					display: block;
					flex-shrink: 0;
					font-size: 1.5em;
					height: 1em;
					margin: 0 0 0 0.3em;
					line-height: 0.66em;
					position: relative;
					width: 1em;
					
					&:before,
					&:after {
						content: "";
						position: absolute;
						border-radius: 50%;
					}
					
					&:before {
						top: 1px;
						right: 1px;
						bottom: 1px;
						left: 1px;
						background: @default-active-color;
						background: var(--plyr-checkbox-bg, @default-active-color);
						z-index: 1;
						transform: scale(0);
						transition: transform 0.25s;
					}
					
					&.is-checked {
						&:before {
							transform: scale(1);
						}
					}
					
					&:after {
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						border: 3px solid #fff;
						z-index: 2;
					}
				}
			}
		}
		
		.video-subscribe {
			display: inline-block;
			border: 1px solid #ddd;
			background: #f00;
			font-weight: bold;
			color: #fff;
			text-decoration: none;
			font-size: 13px;
			line-height: 15px;
			.sub-btn {
				display: inline-block;
				background: #fff;
				color: #f00;
				padding: 0 3px 0 4px;
				margin: 2px;
				line-height: 13px;
				border-radius: 1px;
				.icon {
					margin: 1px 0px;
					vertical-align: bottom;
				}
			}
			.nb-subscribers {
				display: inline-block;
				background: #fff;
				color: #000;
				padding: 1px 2px;
				font-weight: normal;
				margin-left: 1px;
			}
			a {
				color: #fff;
			}
			.notouch &:hover {
				& {
					background: @default-active-color;
				}
				.sub-btn {
					color: @default-active-color;
				}
			}
		}
		
		.sponsor-popup {
			background-color: rgba(0, 0, 0, 1);
			color: #fff;
			text-align: center;
			position: absolute;
			left: 10%;
			width: 80%;
			//bottom: 20%;
			padding: 4px;
			a {
				color: #fff;
				text-decoration: underline;
			}
			p {
				margin: 0;
				padding: 0;
			}
			.sponsor-desc {
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				text-align: center;
				font-weight: normal;
			}
			.sponsor-click {
				position: absolute;
				top: 10%;
				left: 3%;
				right: 10%;
				bottom: 10%;
			}
			.dlg-close-btn {
				position: absolute;
				top: 0;
				right: -5px;
				background: rgba(0, 0, 0, 1);
			}
		}
		
		.related-vids {
			position: absolute;
			z-index: @zindex-embedresponsiveitem-related;
			
			.related {
				position: relative;
				overflow: hidden;
			}
			&.desktop-view {
				.related-video {
					.thumb {
						a {
							.title {
								font-size: 16px;
							}
						}
					}
				}
			}
			
			.related-video {
				color: #fff;
				box-sizing: border-box;
				display: inline-block;
				padding: 4px;
				position: relative;
				text-align: center;
				vertical-align: top;
				z-index: 1;
				
				.thumb {
					bottom: 4px;
					left: 4px;
					position: absolute;
					right: 4px;
					top: 4px;
					width: auto;
					
					a {
						bottom: 0;
						color: #fff;
						display: block;
						left: 0;
						overflow: visible;
						position: absolute;
						right: 0;
						text-decoration: none;
						top: 0;
						
						img {
							display: block;
							height: 100%;
							object-fit: cover;
							width: 100%;
						}
						
						.duration {
							background: rgba(0, 0, 0, 0.7);
							bottom: 1px;
							color: #fff;
							font-size: 13px;
							font-weight: bold;
							opacity: 0;
							padding: 2px;
							position: absolute;
							right: 1px;
							text-decoration: none;
						}
						
						.ad {
							position: absolute;
							left: 4px;
							bottom: 4px;
							background: @default-active-color;
							color: #fff;
							font-weight: bold;
							border-radius: 3px;
							height: 21px;
							line-height: 23px;
							font-size: 16px;
							padding: 0 5px;
						}
						
						.title {
							background: rgba(0, 0, 0, 0.6);
							font-size: 14px;
							font-weight: normal;
							left: 0;
							margin: 0;
							opacity: 0;
							padding: 4px 6px;
							position: absolute;
							right: 0;
							text-align: center;
							top: 0;
						}
					}
					
					.progress {
						background: #000;
						bottom: 0;
						left: 0;
						height: 2px;
						margin-bottom: 0;
						position: absolute;
						right: 0;
						width: 100%;
						
						.bar {
							background: #999;
							height: 2px;
							width: 0;
						}
					}
				}
				
				&.one-line {
					.thumb {
						background: #000;
						
						& > a {
							min-height: 81%;
							bottom: 18%;
							
							.duration {
								opacity: 1;
							}
							.title {
								.line-clamp(2);
								opacity: 1;
								padding: 0 6px;
								top: 112.5%;
								transform: translateY(-50%);
							}
						}
					}
				}
				
				&.rotator-started {
					z-index: 2;
					
					.thumb {
						a {
							.duration,
							.title {
								opacity: 1;
							}
						}
					}
				}
				
				&:not(.one-line) {
					&.rotator-started {
						.thumb {
							.videopv {
								bottom: 0;
								display: block;
								left: 0;
								position: absolute;
								right: 0;
								top: 0;
							}
							
							img,
							.videopv {
								box-shadow: -1px -1px 0 1px #999, 1px -1px 0 1px #999;
							}
							
							& > a {
								.title {
									background: #000;
									box-shadow: -1px 1px 0 1px #999, 1px 1px 0 1px #999;
									color: #fff;
									font-weight: bold;
									padding: 10px;
									top: 100%;
								}
							}
						}
					}
					
					&.bottom-line {
						&.rotator-started {
							.thumb {
								.videopv {
									bottom: 1px;
								}
								
								img,
								.videopv {
									box-shadow: -1px 1px 0 1px #999, 1px 1px 0 1px #999;
								}
								
								& > a {
									.title {
										bottom: 100%;
										box-shadow: -1px -1px 0 1px #999, 1px -1px 0 1px #999;
										top: auto;
									}
								}
							}
						}
					}
				}
				
				
				.border {
					cursor: pointer;
					background: rgba(0, 0, 0, 0.7);
					border: 1px #888 solid;
					max-height: 100%;
					overflow: hidden;
				}
				.sponsor {
					a {
						text-decoration: underline;
					}
				}
				.sponsor-desc {
					text-overflow: ellipsis;
					overflow: hidden;
					max-height: 90%;
					font-weight: normal;
					text-align: center;
				}
				
			}
		}
		
		.pl-next {
			position: absolute;
			width: 50%;
			background: #fff;
			box-shadow: 0 0 0 4px black;
			padding: 4px;
			max-width: 600px;
			min-width: 400px;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			cursor: pointer;
			.thumb {
				width: 100%;
			}
			.loader,
			.pl-timer {
				position: absolute;
				left: 40%;
				top: 40%;
				color: #fff;
				font-weight: bold;
			}
			p {
				position: absolute;
				left: 5px;
				top: 5px;
				max-width: 90%;
				margin: 0;
				background-color: rgba(0, 0, 0, 0.5);
				color: #fff;
				font-weight: bold;
				text-align: left;
			}
			.dlg-close-btn {
				position: absolute;
				top: -20px;
				right: -20px;
				background: rgba(0, 0, 0, 0.5);
			}
		}
		
		&.pl-mobile .pl-next {
			width: 70%;
		}
		&.pl-mobile-small .pl-next {
			width: 80%;
		}
		
		&.desktop {
			.progress-bar {
				.seek-cursor {
					bottom: 150%;
				}
			}
			.related-vids {
				.related {
					margin: auto;
					text-align: center;
					overflow: hidden;
					position: static;
				}
			}
			
			.slowseek-info {
				.label {
					font-size: 18px;
					line-height: 24px;
				}
				
				&::after {
					top: 12px;
				}
				
				&::before {
					top: 15px;
				}
			}
		}
	}
	&.desktop {
		#hlsplayer {
			.slowseek-info {
				.label {
					font-size: 18px;
					line-height: 24px;
					
					&::after {
						top: 12px;
					}
					
					&::before {
						top: 15px;
					}
				}
			}
		}
	}
}

#html5video .pl-msg-box,
#page.video-page #html5video .pl-msg-box,
.pl-msg-box {
	box-sizing: border-box;
	font-size: 14px;
	font-weight: normal;
	color: #000;
	* {
		box-sizing: border-box;
	}
	h2 {
		font-weight: bold;
		line-height: 26px;
		font-size: 18px;
		margin: 0 0 10px;
		background: none;
		color: #000;
	}
	h3 {
		font-weight: bold;
		line-height: 18px;
		font-size: 15px;
		margin: 0 0 10px;
	}
}

.pl-msg-box {
	padding: 5px 15px 10px;
	text-align: center;
	border: 2px solid #000;
	background: #fff;
	p {
		font-weight: normal;
	}
	.pl-error {
		background: lighten(@default-red-color, 50%);
		color: @default-active-color;
		border-left: 4px solid lighten(@default-red-color, 30%);
		padding: 5px 15px;
		margin: 5px 0;
		font-size: 13px;
		font-weight: bold;
		text-align: left;
	}
}

.pl-dl-form {
	input[type="text"],
	input[type="password"],
	button {
		width: 100%;
		display: block;
		background: #fff;
		border: 1px solid #999;
		border-radius: 2px;
		height: 24px;
		padding: 2px 8px;
		margin: 0 0 3px;
		font-size: 13px;
		font-weight: bold;
		line-height: 1.5;
		box-shadow: none;
		color: #222;
	}
	button {
		color: #fff;
		background: #000;
		border-color: #000;
		font-size: 16px;
		height: 28px;
		margin: 5px 0 0;
		.notouch &:hover {
			background: #222;
		}
	}
	.pl-login {
		display: block;
		width: 50%;
		float: left;
		border-right: 2px solid #ccc;
		padding-right: 15px;
		.pl-forgot-pwd {
			margin-top: 10px;
			a {
				font-weight: normal;
				color: #000;
			}
		}
		.pl-tos {
			display: none;
			text-align: left;
			input {
				vertical-align: middle;
				padding-right: 5px;
			}
			label {
				font-weight: bold;
				display: inline;
				font-size: 11px;
				a {
					color: #000;
				}
			}
		}
	}
	.pl-create-account {
		overflow: hidden;
		padding-left: 15px;
		.not-member {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 52px;
			font-weight: bold;
			font-style: italic;
		}
		input[type="text"],
		input[type="password"] {
			display: none;
		}
		.create-acct-slogan {
			margin-top: 10px;
			font-size: 11px;
			font-weight: bold;
			font-style: italic;
			line-height: 14px;
		}
	}
	.pl-clear {
		clear: both;
	}
	.pl-footer {
		color: #111;
		font-style: italic;
		line-height: 16px;
		font-size: 13px;
		margin-top: 10px;
		text-align: left;
	}
	&.pl-create {
		.pl-login {
			button,
			.pl-forgot-pwd {
				display: none;
			}
			.pl-tos {
				display: block;
			}
		}
		.pl-create-account {
			.not-member,
			.create-acct-slogan {
				display: none;
			}
			input[type="text"],
			input[type="password"] {
				display: block;
			}
		}
	}
}
#html5video .pl-mobile,
#page.video-page #html5video .pl-mobile,
.pl-mobile {
	.pl-dl-form {
		padding: 5px 10px 5px;
		font-size: 13px;
		h3 {
			line-height: 17px;
			font-size: 13px;
			margin-bottom: 2px;
		}
		input[type="text"],
		input[type="password"],
		button {
			font-size: 13px;
			height: 24px;
		}
		.pl-footer {
			line-height: 15px;
			font-size: 12px;
			margin-top: 5px;
		}
	}
}
#html5video .pl-mobile-small,
#page.video-page #html5video .pl-mobile-small,
.pl-mobile-small {
	.pl-dl-form {
		h3 {
			line-height: 15px;
			font-size: 12px;
			margin-bottom: 5px;
		}
		input[type="text"],
		input[type="password"],
		button {
			padding: 1px 4px;
			font-size: 11px;
			height: 20px;
		}
		.pl-login {
			.pl-forgot-pwd {
				margin-top: 3px;
			}
			.pl-tos {
				label {
					font-weight: normal;
				}
			}
		}
		.pl-create-account {
			.not-member {
				font-size: 11px;
				height: 44px;
			}
			.create-acct-slogan {
				margin-top:3px;
			}
		}
		.pl-footer {
			line-height: 14px;
			font-size: 11px;
			margin-top: 3px;
		}
	}
	.pl-error {
		padding: 1px 5px;
		font-size: 11px;
	}
}

.pl-dl-box {
	.pl-dl-btns {
		overflow: hidden;
		a {
			display: block;
			text-decoration: none;
			background: #000;
			color: #fff;
			padding: 20px 10px;
			font-size: 20px;
			.notouch &:hover {
				background: #222;
			}
		}
		&.two-btns {
			.low-qual {
				float: left;
				width: 50%;
				border-right: 5px solid #fff;
			}
			.high-qual {
				border-left: 5px solid #fff;
			}
		}
	}
}

.pl-mobile .pl-dl-box {
	.pl-dl-btns {
		a {
			font-size: 18px;
			padding: 10px;
		}
		&.two-btns {
			.low-qual {
				float: none;
				width: auto;
				border-right: none;
				margin-bottom: 5px;
			}
			.high-qual {
				border-left: none;
			}
		}
	}
}


.videoad-base {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #000;
	z-index: @zindex-ad;
}

.videoad-video {
	position: absolute;
	width: 100%;
	height: 100%;
}

.videoad-click {
	position: absolute;
	width: 100%;
	height: 100%;
}

.videoad-nocontrol-ios::-webkit-media-controls-play-button,
.videoad-nocontrol-ios::-webkit-media-controls-start-playback-button {
	opacity: 0;
	pointer-events: none;
	width: 5px;
}

.videoad-skip {
	background-color: #000;
	border: 2px #999 solid;
	border-color: rgba( 255,255,255,0.35 );
	bottom: 0;
	color: #FFF;
	color: rgba( 255,255,255,0.8 );
	cursor: default;
	height: 40px;
	line-height: 40px;
	margin: 5px;
	max-width: 90%;
	max-width: calc( ~"95% - 24px" );
	position: absolute;
	right: 0;
	transition: all 0.2s ease;
	border-radius: 5px;
	//width: 200px;
	.player-icon-f{
		color: rgba( 255,255,255,0.5 );
		transition: color 0.2s ease;
		margin-left: 3px;
		vertical-align: bottom;
	}
	
	#html5video #hlsplayer & {
		.player-icon-f {
			color: rgba(255, 255, 255, 0.5);
		}
		
		.notouch & {
			&.skippable:hover {
				.player-icon-f {
					color: #FFF;
				}
			}
		}
	}
	.notouch & {
		background-color: rgba(0, 0, 0, 0.6);
		
		&:hover {
			background-color: #000;
		}
		
		&.skippable:hover {
			border-color: #FFF;
			color: #FFF;
			cursor: pointer;
			
			.player-icon-f{
				color: #FFF;
			}
		}
	}
	.videoad-skip-txt {
		display: inline-block;
		font-size: 18px;
		font-weight: bold;
		line-height: 18px;
		padding: 0 10px;
		//vertical-align: middle;
		white-space: nowrap;
	}
}



.videoad-title {
	font-size: 16px;
	font-weight: bold;
	//height: 16px;
	margin: 0;
	right: 0;
	padding: 10px 15px;
	position: absolute;
	text-align: left;
	top: 0;
	transition: all 0.2s ease;
	
	&,
	&:focus,
	&:active,
	&:hover {
		background-color: #000;
		background-color: rgba(0,0,0,0.5);
		color: #FFF;
		cursor: pointer;
		text-decoration: none;
	}
	
	&.videoad-title-beforevideo {
		background-color: rgba(216, 42, 30, 0.75);
		border: 2px #d03328 solid;
		.notouch &:hover {
			background-color: rgba(169, 36, 27, 0.95);
		}
	}

	// &.sheer-sponsor {
	// 	background-color: fadeOut(#6f42c1, 25%);
	// }
}

#centered-full-video-link {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: absolute;
	text-align: center;
	z-index: @zindex-centered-full-video-link;
	
	a {
		background: black;
		border: 2px solid #fff;
		border-radius: 3px;
		color: white;
		display: inline-block;
		font-size: 20px;
		padding: 8px 14px;
		text-decoration: none;
		
		&:hover {
			background: #333;
		}
		
		.logo {
			display: block;
		}
		.thumb {
			border-radius: 10px;
			box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
			display: block;
			margin: 4px auto 10px;
			max-width: 90%;
			width: 180px;
		}
		
		&.is-sh {//is sheer
			background: #eee;
			border: 0;
			border-radius: 50px;
			color: #333;
			padding: 20px 30px 16px;
			
			img.sheer {
				display: block;
				margin: 10px auto 0;
				max-width: 90%;
				width: 160px;
			}
			
			&::before,
			&::after {
				border-radius: 50px;
				content: "";
				position: absolute;
				//transition: all 0.2s;
			}
			&::before {
				background: linear-gradient(90deg,#51c8e9 40%,#893acc 120%);
				
				bottom: -3px;
				right: -3px;
				left: -3px;
				top: -3px;
				z-index: -2;
			}
			&::after {
				background: #eee;
				box-shadow: 0 0 5px inset rgba(0, 0, 0, 0.5);
				
				bottom: 0;
				right: 0;
				left: 0;
				top: 0;
				z-index: -1;
			}
			
			&:hover {
				&::before {
					//background: linear-gradient(135deg,#51c8e9 40%,#893acc 120%);
					bottom: -4px;
					right: -4px;
					left: -4px;
					top: -4px;
				}
				&::after {
					background: #fff;
					box-shadow: 0 0 3px inset rgba(0, 0, 0, 0.5);
					bottom: -1px;
					right: -1px;
					left: -1px;
					top: -1px;
				}
			}
		}
	}
}

.videoad-title-invideo {
	font-size: 12px;
	padding: 5px 10px;
	z-index: @zindex-sponsor-topright;
}

.videoad-title-txt {
	display: inline;
}

.videoad-title-icon,
.videoad-centerlink-icon {
	display: inline-block;
	font-size: 1.2em;
	line-height: 0.833em;
	margin-left: 6px;
	
	.player-icon-f {
		display: inline-block;
		font-size: 1em;
		line-height: 0.833em;
		vertical-align: middle;
	}
}

.videoad-centerlink-superdiv {
	position: absolute;
	width: 100%;
	text-align: center;
	margin: 10px;
}

.videoad-centerlink {
	background-color: rgba(216, 42, 30, 0.75);
	border-color: #d03328;
	border-radius: 5px;
	color: #FFF;
	cursor: pointer;
	display: inline-block;
	font-size: 16px;
	font-weight: bold;
	//height: 16px;
	bottom: 0;
	margin: 0;
	padding: 10px 15px;
	text-align: left;
	transition: all 0.2s ease;
	
	.notouch &:hover {
		background-color: rgba(169, 36, 27, 0.95);
	}
}

.videoad-centerlink-txt {
	display: inline;
}

.videoad-status {
	vertical-align: middle;
	color: #FFF;
	font-size: 12px;
	font-weight: normal;
	margin-left: 0.5em;
	left: 0;
	bottom: 0;
	height: 100%;
	display: inline;
}

.videoad-mute,
.videoad-unmute {
	border-radius: 50%;
	bottom: 0;
	cursor: pointer;
	display: block;
	height: 20px;
	left: 0;
	margin: 8px;
	padding: 15px;
	position: absolute;
	width: 20px;
}
.videoad-mute {
	animation: pulse 2s 1s ease-out;
	box-shadow: 0 0 0 rgba(255,255,255, 0.2);
	.notouch &:hover {
		animation: pulse 2s ease-out infinite;
	}
}
.videoad-unmute {
	display: none;
}
.muted {
	.videoad-mute {
		animation: none;
		display: none;
	}
	.videoad-unmute {
		display: block;
	}
}

.progress-bar-cursor {
	height: 100%;
	background-color: #ccd232;
}

.info-button {
	background-color: #1c1c1c;
	border: 1px solid #555;
	color: white;
	padding: 1px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 10px;
	margin: 8px;
	cursor: pointer;
	border-radius: 50%;
	width: 15px;
	height: 15px;
	line-height: 15px;
}

.info-button:hover {
	background-color: #4d4d4d; /* Couleur de fond au survol */
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(255,255,255, 0.2);
		background: rgba(255,255,255, 0);
	}
	35% {
		background: rgba(255,255,255, 0.1);
	}
	70% {
		box-shadow: 0 0 0 10px rgba(255,255,255, 0);
	}
	100% {
		box-shadow: 0 0 0 0 rgba(255,255,255, 0);
		background: rgba(255,255,255, 0);
	}
}

@media (max-width: 480px) or (max-height: 480px) {
	#html5video {
		#hlsplayer {
			.settings-menu {
				.setting {
					padding: 0.15em 0.5em;
				}
			}
		}
	}
}

@media (max-width: 480px) {
	.videoad-title{
		font-size: 16px;
		padding: 8px 10px;
	}
	.videoad-title-invideo {
		font-size: 12px;
		padding: 5px 10px;
	}
	.videoad-skip {
		height: 30px;
		line-height: 28px;
		margin: 2px;
		
		.videoad-skip-txt {
			font-size: 15px;
			line-height: 17px;
		}
	}
	
	.videoad-centerlink {
		font-size: 16px;
		//padding: 10px 16px;
		padding: 8px 10px;
	}

	#html5video #hlsplayer.pl-mobile .pl-next {
		min-width: unset;
		width: 75%;
	}
}
@media (max-width: 360px) {
	.videoad-skip {
		height: 27px;
		line-height: 25px;
		margin: 1px;
		.videoad-skip-txt {
			font-size: 14px;
		}
	}
	
	#html5video #hlsplayer.pl-mobile .pl-next {
		width: 80%;
	}
}
@media (max-width: 280px) {
	.videoad-skip {
		height: 25px;
		line-height: 23px;
		.videoad-skip-txt {
			font-size: 13px;
		}
	}
}
